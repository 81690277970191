import { Box, Typography } from "@mui/material";
import React from "react";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

function GetApp() {
  return (
    <Box component="section" sx={{ p: 2 }}>
      <Typography variant="h6">
        If you got here because I invited you, you know where the app is.
      </Typography>
    </Box>
  );
}

export default GetApp;
