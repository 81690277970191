import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import firebaseAuth from "./Services/auth";
import { logEvent } from "./Services/firebase";
import log from "./Tools/Log";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      log.trace("Logout page");
      log.trace(firebaseAuth.currentUser);

      firebaseAuth.signOut().then(() => {
        navigate("/");
      });
    } catch (error) {
      navigate("/");
    }
  }, []);

  return (
    <Box
      component="section"
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack spacing={1}>
        <Typography variant="h6">Logging out...</Typography>
        <Typography variant="body1">
          If you are not automatically redirected,{" "}
          <Link to="/">click here</Link>.
        </Typography>
      </Stack>
    </Box>
  );
}

export default Logout;
