import {
  Avatar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

function UserFeedback() {
  return (
    <Box component="section" sx={{}}>
      <Typography variant="h6">UserFeedback</Typography>
      <Typography variant="body1">...</Typography>
    </Box>
  );
}

export default UserFeedback;
