import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  ImageList,
  ImageListItem,
  Modal,
  Slider,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

const ImagePicker = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  optionsData,
}) => {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
  };
  const [assetsList, setAssetsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async async => {
    log.trace("fetchData");

    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      const filters = { genType: "img" };

      // load assets for user
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getAssets`,
          {
            filters,
          },
          { headers }
        )
        .then(response => {
          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` + item.filePath;
          });

          log.trace("response.data", response.data);
          log.trace(response.data);

          if (response.data && response.data.length > 0) {
            setAssetsList(response.data);
          }
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          log.trace("fetchData done");
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleClose = e => {
    onClose();
  };

  const handleConfirmation = (e, image) => {
    onContinue(image);
  };

  return (
    <>
      {loading ? (
        <Backdrop
          sx={theme => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Dialog
          fullWidth={800}
          maxWidth={800}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
            <ImageList
              variant="masonry"
              cols={Math.min(9, 3 + Math.floor(assetsList.length / 3))}
              gap={8}
            >
              {assetsList.map(asset => (
                <ImageListItem key={asset.uuid}>
                  <img
                    srcSet={`${asset.imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${asset.imageUrl}?w=248&fit=crop&auto=format`}
                    alt={asset.createdAt}
                    loading="lazy"
                    onClick={e => handleConfirmation(e, asset)}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmation} color="primary">
              From Computer
            </Button>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ImagePicker;
