import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

/**
    Honesty-humility (H):
    Facets: Sincerity, fairness, greed avoidance, modesty
    Adjectives: {sincere, honest, faithful, loyal, modest/unassuming} versus {sly, deceitful, greedy, pretentious, hypocritical, boastful, pompous}
    Emotionality (E):
    Facets: Fearfulness, Anxiety, Dependence, Sentimentality
    Adjectives: {emotional, oversensitive, sentimental, fearful, anxious, vulnerable} versus {brave, tough, independent, self-assured, stable}
    Extraversion (X):
    Facets: Social self-esteem, social boldness, sociability, liveliness
    Adjectives: {outgoing, lively, extraverted, sociable, talkative, cheerful, active} versus {shy, passive, withdrawn, introverted, quiet, reserved}
    Agreeableness (A):
    Facets: Forgivingness, gentleness, flexibility, patience
    Adjectives: {patient, tolerant, peaceful, mild, agreeable, lenient, gentle} versus {ill-tempered, quarrelsome, stubborn, choleric}
    Conscientiousness (C):
    Facets: Organization, diligence, perfectionism, prudence
    Adjectives: {organized, disciplined, diligent, careful, thorough, precise} versus {sloppy, negligent, reckless, lazy, irresponsible, absent-minded}
    Openness to experience (O):
    Facets: Aesthetic appreciation, inquisitiveness, creativity, unconventionality
    Adjectives: {intellectual, creative, unconventional, innovative, ironic} versus {shallow, unimaginative, conventional}  
 */

function LearningPersonality() {
  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Typography variant="h6">Personality</Typography>
      <Divider sx={{ p: 1 }} />
      <Typography variant="subtitle1" sx={{ fontStyle: "italic", p: 2 }}>
        &quot;Personality is any person&apos;s collection of interrelated
        behavioral, cognitive and emotional patterns that comprise a
        person&apos;s unique adjustment to life.&quot;
      </Typography>
      <Typography variant="body1"></Typography>
      Personalities here loosely follows the HEXACO model, created by Ashton and
      Lee and explained in their book, The H Factor of Personality. It is not
      intended to be an accurate representation of behavior, but rather a blue
      print that will help define the character&quot;s actions.
      <List sx={{ width: "100%", maxWidth: 360 }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>H</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Honesty-humility"
            secondary="Facets: Sincerity, fairness, greed avoidance, modesty"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>E</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Emotionality"
            secondary="Facets: Fearfulness, Anxiety, Dependence, Sentimentality"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>X</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Extraversion"
            secondary="Facets: Social self-esteem, social boldness, sociability, liveliness"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>A</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Agreeableness"
            secondary="Facets: Forgivingness, gentleness, flexibility, patience"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>C</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Conscientiousness"
            secondary="Facets: Organization, diligence, perfectionism, prudence"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>O</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Openness to experience"
            secondary="Facets: Aesthetic appreciation, inquisitiveness, creativity, unconventionality"
          />
        </ListItem>
      </List>
    </Box>
  );
}

export default LearningPersonality;
