import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../../Services/auth";
import { logEvent } from "../../Services/firebase";
import { truncateIfNeeded } from "../../Tools/DataUtils";
import log from "../../Tools/Log";
import ConfirmationDialog from "../../UiComponents/ConfirmationDialog";
import PagePreview from "./PagePreview";

function Pages() {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogConfirmationData, setDialogConfirmationData] = useState({});
  const [alertMessage, setAlertMessage] = useState({});

  const [openPreview, setOpenPreview] = React.useState(false);
  const [previewPageData, setPreviewPageData] = useState();
  const [locationsList, setLocationsList] = useState({ options: [] });

  const navigate = useNavigate();

  const pageParams = useParams();
  const [bookId] = useState(pageParams.id);

  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    log.trace("handleCloseDialog");
    setOpenDialog(false);
    setDialogConfirmationData({});
  };

  const handleConfirmDialog = async () => {
    log.trace("handleConfirmDialog");
    handleCloseDialog();

    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/deletePage`,
          {
            bookUuid: bookId,
            pageUuid: dialogConfirmationData.uuid,
          },
          { headers }
        )
        .then(response => {
          log.trace("response.data", response.data);
          log.trace(response.data);
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          setDialogConfirmationData({});
          setLoading(false);
          fetchData();
        });
    } catch (error) {
      console.error("Error requesting action:", error);
    }
  };

  const handleCreateClick = () => {
    log.trace("create new page");
    navigate("/console/books/" + bookId + "/pages/new/edit");
  };

  const handleEditClick = (event, pageId) => {
    log.trace("edit book " + bookId + " page " + pageId);
    navigate("/console/books/" + bookId + "/pages/" + pageId + "/edit");
  };

  const handleDeleteClick = async (event, pageId, pageNumber) => {
    log.trace("confirm delete page " + pageId);

    setDialogConfirmationData({ uuid: pageId, title: pageNumber });

    handleOpenDialog();
  };

  const showPagePreview = (pageData, state = true) => {
    log.trace("showPagePreview");

    setPreviewPageData(pageData);
    setOpenPreview(state);
  };

  const handlePageUpClick = async (event, pageUuid, pageNumber, i) => {
    log.trace("handlePageUpClick");

    if (pages.length < 2) {
      setAlertMessage({
        message: "Book has only one page.",
        severity: "warning", //success info warning error
      });
    } else if (pages[0].uuid == pageUuid) {
      // already first page
      setAlertMessage({
        message: "This is already the first page.",
        severity: "warning", //success info warning error
      });
    } else {
      // TODO
      //setAlertMessage({});
      swapPageNumbers(pages[i - 1].uuid, pageUuid);
    }
  };

  const handlePageDownClick = async (event, pageUuid, pageNumber, i) => {
    log.trace("handlePageDownClick");

    if (pages.length < 2) {
      setAlertMessage({
        message: "Book has only one page.",
        severity: "warning", //success info warning error
      });
    } else if (pages.at(-1).uuid == pageUuid) {
      // already last page
      setAlertMessage({
        message: "This is already the last page.",
        severity: "warning", //success info warning error
      });
    } else {
      // TODO
      //setAlertMessage({});
      swapPageNumbers(pageUuid, pages[i + 1].uuid);
    }
  };

  const swapPageNumbers = async (leftPageUuid, rightPageUuid) => {
    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/swapPageNumber`,
          {
            bookUuid: bookId,
            leftPageUuid: leftPageUuid,
            rightPageUuid: rightPageUuid,
          },
          { headers }
        )
        .then(response => {
          log.trace("response.data", response.data);
          log.trace(response.data);
          fetchData();
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error requesting action:", error);
    }
  };

  useEffect(() => {
    log.trace("--[useEffect]-----------------------------------");
    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("--[fetchData]-----------------------------------");

    try {
      setLoading(true);
      setAlertMessage({});

      const filters = {
        //TODO replace with implementation later
        title: "search*",
      };

      log.trace("fetchData with filters");
      log.trace(filters);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getPages`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          { headers }
        )
        .then(response => {
          // Handle the response
          log.trace("response");
          log.trace(response);

          response.data.map(item => {
            item.backgroundImage &&
              (item.imageUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.backgroundImage);

            item.narrativeAudio &&
              (item.audioUrl =
                `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                item.narrativeAudio);
          });

          setPages(response.data);
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          log.trace("fetchData done");
          setLoading(false);
        });

      // load locations select
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getLocations`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          { headers }
        )
        .then(response => {
          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          log.trace("response.data", response.data);
          log.trace(response.data);

          if (response.data && response.data.length > 0) {
            setLocationsList({
              locations: response.data,
              options: response.data.map(item => item.name),
            });
          }
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          log.trace("fetchData done");
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  return (
    <Box component="section" sx={{}}>
      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <Backdrop
            sx={theme => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
            open={openPreview}
          >
            <PagePreview
              openPreview={openPreview}
              pageData={previewPageData}
              locationData={
                (previewPageData &&
                  previewPageData.location &&
                  locationsList.locations.filter(
                    location => location.name === previewPageData.location
                  )[0]) ||
                null
              }
              onClick={e => showPagePreview({}, false)}
            />
          </Backdrop>

          {alertMessage.message && (
            <Alert severity={alertMessage.severity}>
              {alertMessage.message}
            </Alert>
          )}

          <ConfirmationDialog
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmDialog}
            title="Confirm Deletion"
            message="Warning! This action is irreversible. Please type the page number to confirm the action."
            promptHelperText="Type the page number to confirm."
            confirmationData={dialogConfirmationData}
          />
          <Grid
            container
            rowSpacing={1}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid size={3}>
              <Button
                onClick={handleCreateClick}
                variant="text"
                sx={{ width: "100%", height: "100%" }}
              >
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AddCircleOutlineOutlinedIcon />
                  CREATE NEW
                </Stack>
              </Button>
            </Grid>
            {pages.map((page, i) => (
              <Grid size={3} key={page.uuid}>
                <Card
                  sx={{
                    maxWidth: 345,
                    height: 345,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    sx={{ height: 140 }}
                    image={page.imageUrl}
                    title={page.pageNumber}
                  />
                  <CardContent>
                    <Stack direction="row" spacing={2}>
                      <IconButton
                        onClick={e =>
                          handlePageUpClick(e, page.uuid, page.pageNumber, i)
                        }
                        color="primary"
                        size="small"
                      >
                        <ArrowLeftIcon />
                      </IconButton>
                      <Typography gutterBottom variant="h5" component="div">
                        #{truncateIfNeeded(page.pageNumber, 8)}
                      </Typography>
                      <IconButton
                        onClick={e =>
                          handlePageDownClick(e, page.uuid, page.pageNumber, i)
                        }
                        color="primary"
                        size="small"
                      >
                        <ArrowRightIcon />
                      </IconButton>
                    </Stack>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      {truncateIfNeeded(page.ideation)}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ mt: "auto" }}>
                    <IconButton
                      onClick={e => showPagePreview(page)}
                      color="primary"
                      size="small"
                    >
                      <PlayCircleOutlineIcon />
                    </IconButton>
                    <IconButton
                      onClick={e => handleEditClick(e, page.uuid)}
                      color="primary"
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={e =>
                        handleDeleteClick(e, page.uuid, page.pageNumber)
                      }
                      color="primary"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default Pages;
