import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";
import LibellusIcon from "./LibellusIcon";

function NavigationBar() {
  const navigate = useNavigate();

  const pages = [
    { title: "Products", route: "/products" },
    { title: "Pricing", route: "/pricing" },
    { title: "About", route: "/about" },
  ];

  const memberSettings = [
    { title: "Profile", route: "/profile" },
    { title: "Dashboard", route: "/checkAccountStateInterstitial" },
    { title: "Logout", route: "/logout" },
  ];

  const guestSettings = [
    { title: "Profile", route: "/profile" },
    { title: "Become a member", route: "/newusermembership" },
    { title: "Logout", route: "/logout" },
  ];

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [navAnchor, setNavAnchor] = React.useState(null);
  const [userdataLoaded, setUserdataLoaded] = React.useState(false);
  const [settingsMenu, setSettingsMenu] = React.useState([]);

  useEffect(() => {
    log.trace("useEffect--");
    log.trace(userdataLoaded);
    log.trace(firebaseAuth.currentUser);
    setSettingsMenu(guestSettings);
  }, []);

  const fetchData = async () => {
    log.trace("fetchData-----------------------");

    const headers = {
      "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
      Authorization: `Bearer ${await firebaseAuth.currentUser.getIdToken()}`,
      "Content-Type": "application/json",
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/checkAuth`, {}, { headers })
      .then(() => {
        log.trace("settings->memberSettings");
        setSettingsMenu(memberSettings);
      })
      .catch(error => {
        //log.trace(error);

        // TODO do we need to cover this case?
        if (error.status == 314) {
          // 314 - session paid; no membership created yet
          log.trace("settings->memberSettings");
          setSettingsMenu(memberSettings);
        } else {
          setSettingsMenu(guestSettings);
        }
      })
      .finally(() => {
        setUserdataLoaded(true);
      });
  };

  const handleOpenNavMenu = event => {
    log.trace("handleOpenNavMenu");
    setNavAnchor(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseNavMenu = route => {
    log.trace("handleCloseNavMenu");

    setNavAnchor(null);
    setMenuOpen(false);

    // if (route) {
    //   navigate(route);
    // }
  };

  firebaseAuth.onAuthStateChanged(async user => {
    log.trace(
      "onAuthStateChanged (" + userdataLoaded + ") " + user?.displayName
    );
    if (user && !userdataLoaded) {
      setUserdataLoaded(true);
      fetchData();
    }
  });

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <LibellusIcon />
          <Typography
            to="/"
            onClick={handleCloseNavMenu}
            variant="h6"
            noWrap
            component={Link}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LIBELL.US
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map(page => (
              <Button
                key={page.title}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
                component={Link}
                to={page.route}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          {userdataLoaded && firebaseAuth.currentUser ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenNavMenu} sx={{ p: 0 }}>
                  {firebaseAuth.currentUser.photoURL ? (
                    <Avatar
                      alt={firebaseAuth.currentUser.displayName}
                      src={firebaseAuth.currentUser.photoURL}
                    />
                  ) : (
                    <Avatar
                      {...stringAvatar(firebaseAuth.currentUser.displayName)}
                    />
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={navAnchor}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={menuOpen}
                onClose={handleCloseNavMenu}
              >
                {settingsMenu.map(setting => (
                  <MenuItem
                    key={setting.title}
                    onClick={() => handleCloseNavMenu(setting.route)}
                  >
                    <Typography
                      sx={{
                        display: { xs: "none", md: "flex" },
                        color: "inherit",
                        textDecoration: "none",
                        textAlign: "center",
                      }}
                      component={Link}
                      to={setting.route}
                    >
                      {setting.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Box sx={{ flexGrow: 0 }}>
              <Button
                key="Login"
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
                component={Link}
                to="/login"
              >
                Login
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavigationBar;
