import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

function NewUserProfile() {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
  };
  const navigate = useNavigate();
  const pageParams = useParams();

  const [userProfileData, setUserProfileData] = useState({
    displayName: "",
    email: "",
    emailVerified: "",
    penName: "",
    customerId: "",
    membership: {},
  });
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  useEffect(() => {
    log.trace("--[useEffect]-------------------------------------");
    log.trace(firebaseAuth.currentUser);

    setUserProfileData({
      displayName: firebaseAuth.currentUser.displayName,
      email: firebaseAuth.currentUser.email,
      emailVerified: firebaseAuth.currentUser.emailVerified,
      penName: "",
      membership: {},
    });
  }, []);

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit");

    try {
      e.preventDefault();

      setLoading(true);

      const formData = new FormData();

      formData.append("userId", firebaseAuth.currentUser.uid);
      formData.append("displayName", userProfileData.displayName);
      formData.append("email", userProfileData.email);
      formData.append("emailVerified", userProfileData.emailVerified);

      if (userProfileData.penName) {
        formData.append("penName", userProfileData.penName);
      }

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "multipart/form-data";

      axios
        .put(`${process.env.REACT_APP_API_URL}/putUser`, formData, {
          headers,
        })
        .then(response => {
          log.trace("response.data", response.data);
          log.trace(response.data);

          log.trace("Profile data submitted successfully!");
          setUserProfileData({});
          navigate("/newusermembership");
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error saving data:", error);
      setLoading(false);
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setUserProfileData({ ...userProfileData, [name]: value });
  };

  const handleCancel = () => {
    navigate("/logout");
  };

  return (
    <Box component="section" sx={{}}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="book-details-content"
                id="book-details-header"
              >
                Create a Profile
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                  <TextField
                    id="name"
                    name="name"
                    fullWidth
                    required
                    readonly
                    value={userProfileData.displayName}
                    label="Name"
                    variant="filled"
                  />
                  <TextField
                    id="email"
                    name="email"
                    fullWidth
                    readonly
                    required
                    value={userProfileData.email}
                    label="Email"
                    variant="filled"
                  />
                  <TextField
                    id="penName"
                    name="penName"
                    fullWidth
                    value={userProfileData.penName}
                    onChange={handleInputChange}
                    multiline
                    maxRows={20}
                    label="Pen Name"
                    variant="outlined"
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Stack spacing={2} direction="row" sx={{ p: 2 }}>
              <Button variant="contained" type="submit" color="primary">
                Next
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default NewUserProfile;
