import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";
import NavigationBar from "./NavigationBar";

function GuestSiteLayout() {
  return (
    <Box component="section" sx={{}}>
      <NavigationBar />
      <Box component="section" sx={{ p: 2 }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default GuestSiteLayout;
