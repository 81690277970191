import "@fontsource/kalam/300.css";
import "@fontsource/kalam/400.css";
import "@fontsource/kalam/700.css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import * as React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import About from "./About";
import "./App.css";
import CheckAccountStateInterstitial from "./CheckAccountStateInterstitial";
import Book from "./Console/Books/Book";
import BookContent from "./Console/Books/BookOverview";
import Books from "./Console/Books/Books";
import Page from "./Console/Books/Page";
import Pages from "./Console/Books/Pages";
import Dashboard from "./Console/Dashboard";
import LearningCenter from "./Console/LearningCenter";
import Membership from "./Console/Membership";
import Profile from "./Console/Profile";
import Traffic from "./Console/Reports/Traffic";
import UserFeedback from "./Console/Reports/UserFeedback";
import Character from "./Console/Supporting/Character";
import Characters from "./Console/Supporting/Characters";
import Location from "./Console/Supporting/Location";
import Locations from "./Console/Supporting/Locations";
import Timelines from "./Console/Supporting/Timelines";
import Error from "./Error";
import LandingPage from "./LandingPage";
import Login from "./Login";
import Logout from "./Logout";
import BetaInvite from "./NewUserFlow/BetaInvite";
import GetApp from "./NewUserFlow/GetApp";
import NewUserMembership from "./NewUserFlow/NewUserMembership";
import NewUserProfile from "./NewUserFlow/NewUserProfile";
import SubscribeCancel from "./NewUserFlow/SubscribeCancel";
import SubscribeSuccess from "./NewUserFlow/SubscribeSuccess";
import Pricing from "./Pricing";
import Privacy from "./Privacy";
import Products from "./Products";
import PublicProfile from "./PublicProfile";
import firebaseAuth from "./Services/auth";
import { logEvent } from "./Services/firebase";
import TermsOfService from "./TermsOfService";
import log from "./Tools/Log";
import BookEditorLayout from "./UiComponents/BookEditorLayout";
import ConsoleLayout from "./UiComponents/ConsoleLayout";
import GuestSiteLayout from "./UiComponents/GuestSiteLayout";

function App() {
  //Everything under path /console should require auth
  // eslint-disable-next-line react/prop-types
  function ProtectedRoute({ children }) {
    log.trace("ProtectedRoute checking");

    if (!firebaseAuth.currentUser) {
      log.trace("REDIRECT to login");
      return <Navigate to="/login" replace />;
    } else {
      return children;
    }
  }

  // TODO required?
  // if (window.location.hostname == "libell-us.web.app" ||
  //   window.location.hostname == "libell-us.firebaseapp.com") {
  //       window.location.href = 'https://libell.us';
  // }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<GuestSiteLayout />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/getapp" element={<GetApp />} />
          <Route path="/betainvite" element={<BetaInvite />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/about" element={<About />} />
          <Route path="/profile" element={<PublicProfile />} />
          <Route path="/products" element={<Products />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/error" element={<Error />} />
          <Route path="/subscribesuccess" element={<SubscribeSuccess />} />
          <Route path="/subscribecancel" element={<SubscribeCancel />} />
          <Route
            path="/checkAccountStateInterstitial"
            element={<CheckAccountStateInterstitial />}
          />
          <Route path="/newuserprofile" element={<NewUserProfile />} />
          <Route path="/newusermembership" element={<NewUserMembership />} />
        </Route>
        <Route
          path="/console"
          element={
            <ProtectedRoute>
              <ConsoleLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="learningcenter" element={<LearningCenter />} />
          <Route path="books" element={<Books />} />
          <Route path="membership" element={<Membership />} />
          <Route path="reports/feedback" element={<UserFeedback />} />
          <Route path="reports/traffic" element={<Traffic />} />
          <Route path="books/:id/edit" element={<Book />} />
        </Route>
        <Route
          path="/console/books/:id"
          element={
            <ProtectedRoute>
              <BookEditorLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<BookContent />} />
          <Route path="pages" element={<Pages />} />
          <Route path="pages/:pid/edit" element={<Page />} />
          <Route path="characters" element={<Characters />} />
          <Route path="characters/:cid/edit" element={<Character />} />
          <Route path="timelines" element={<Timelines />} />
          <Route path="locations" element={<Locations />} />
          <Route path="locations/:lid/edit" element={<Location />} />
          <Route path="learningcenter" element={<LearningCenter />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
