import log from "loglevel";

/**
    TRACE: 0;
    DEBUG: 1;
    INFO: 2;
    WARN: 3;
    ERROR: 4;
    SILENT: 5;
 */
// TODO set it based on environment config; process.env.REACT_APP_LOG_LEVEL
const logLevel = "warn";
log.setLevel(logLevel);

export default log;
