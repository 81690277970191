import { Box, Typography } from "@mui/material";
import React from "react";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

function Membership() {
  return (
    <Box component="section" sx={{}}>
      <Typography variant="h6">Membership</Typography>
      <Typography variant="body1">...</Typography>
    </Box>
  );
}

export default Membership;
