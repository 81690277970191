import { Box, Typography } from "@mui/material";
import * as React from "react";
import { logEvent } from "./Services/firebase";
import log from "./Tools/Log";

function Products() {
  return (
    <Box component="section" sx={{}}>
      <Typography variant="h2">Products</Typography>
      <Typography variant="h6">invite only</Typography>
    </Box>
  );
}

export default Products;
