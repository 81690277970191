import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

function SubscribeSuccess() {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
    "Content-Type": "application/json",
  };
  const navigate = useNavigate();
  const pageParams = useParams();

  const [userProfileData, setUserProfileData] = useState({
    displayName: "",
    email: "",
    emailVerified: "",
    penName: "",
    customerId: "",
    membership: {},
    fbcu_email: "",
  });
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setAlertMessage({});

    if (!firebaseAuth.currentUser) return;

    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getUserProfile`,
          {},
          { headers }
        )
        .then(async response => {
          log.trace("response.data", response.data);
          log.trace(response.data);

          setUserProfileData(response.data);

          if (
            !response.data.membership ||
            response.data.membership.status !== "active"
          ) {
            setAlertMessage({
              message:
                "Your payment transaction is taking a bit longer to process. If you don't get access within a few minutes or you need assistence, please contact our support.",
              severity: "warning", //success info warning error
            });
          }
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  // Listen to change in auth state so it displays the correct UI for when
  // the user is signed in or not.
  firebaseAuth.onAuthStateChanged(user => {
    log.trace("onAuthStateChanged");
    log.trace(user);

    if (
      user &&
      firebaseAuth.currentUser &&
      !userProfileData.email &&
      !userProfileData.fbcu_email
    ) {
      setUserProfileData({ fbcu_email: user.email });
      fetchData();
    }
  });

  return (
    <Box component="section" sx={{ p: 2 }}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <Typography variant="h5" sx={{ pb: 2 }}>
            We welcome our new member {userProfileData.displayName}!
          </Typography>
          <Stack spacing={2} direction="row">
            {userProfileData.membership &&
            userProfileData.membership.status === "active" ? (
              <Button
                key="startBuilding"
                sx={{ my: 2, display: "block" }}
                component={Link}
                to="/console"
              >
                Start Building!
              </Button>
            ) : (
              <Button
                key="startBuirefreshlding"
                sx={{ my: 2, display: "block" }}
                component={Link}
                onClick={fetchData}
              >
                Refresh
              </Button>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default SubscribeSuccess;
