import { Box, Typography } from "@mui/material";
import React from "react";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

function Traffic() {
  return (
    <Box component="section" sx={{}}>
      <Typography variant="h6">Traffic</Typography>
      <Typography variant="body1">...</Typography>
    </Box>
  );
}

export default Traffic;
