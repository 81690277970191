import {
  Alert,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

function Dashboard() {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
  };
  const navigate = useNavigate();
  const pageParams = useParams();

  const [dashboardData, setDashboardData] = useState({
    numberOfBooks: 0,
    aiTextGenerationsTotal: 0,
    aiTextGenerationsPerCycle: 0,
    aiImageGenerationsTotal: 0,
    aiImageGenerationsPerCycle: 0,
    aiAudioGenerationsTotal: 0,
    aiAudioGenerationsPerCycle: 0,
  });
  const [alertMessage, setAlertMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(`${process.env.REACT_APP_API_URL}/getDashboard`, {}, { headers })
        .then(response => {
          log.trace("response.data", response.data);
          log.trace(response.data);

          setDashboardData(response.data);
        })
        .catch(error => {
          log.error(error);

          //TODO should be checked on all pages; how ?
          // if new member or no membership, redirect
          if (error.status == 310) {
            // 310 - not a member
            navigate("/newuserprofile");
          } else if (error.status == 311) {
            // 311 - no membership
            navigate("/newusermembership");
          } else {
            // server error
            log.error(error);
            firebaseAuth.signOut().then(() => {
              navigate("/error");
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  return (
    <Box component="section" sx={{}}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}
      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section">
          <Stack direction="row" spacing={2}>
            <Card
              variant="outlined"
              sx={{
                textAlign: "center",
                width: "150px",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  BOOKS
                </Typography>
                <Typography variant="h5" component="div">
                  {dashboardData.numberOfBooks}
                </Typography>
              </CardContent>
            </Card>

            <Card
              variant="outlined"
              sx={{ textAlign: "center", width: "150px" }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  TEXT GENERATION
                </Typography>
                <Typography variant="h5" component="div">
                  {dashboardData.aiTextGenerationsTotal} of{" "}
                  {dashboardData.aiTextGenerationsPerCycle}
                </Typography>
              </CardContent>
            </Card>

            <Card
              variant="outlined"
              sx={{ textAlign: "center", width: "150px" }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  IMAGE GENERATION
                </Typography>
                <Typography variant="h5" component="div">
                  {dashboardData.aiImageGenerationsTotal} of{" "}
                  {dashboardData.aiImageGenerationsPerCycle}
                </Typography>
              </CardContent>
            </Card>

            <Card
              variant="outlined"
              sx={{ textAlign: "center", width: "150px" }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  AUDIO GENERATION
                </Typography>
                <Typography variant="h5" component="div">
                  {dashboardData.aiAudioGenerationsTotal} of{" "}
                  {dashboardData.aiAudioGenerationsPerCycle}
                </Typography>
              </CardContent>
            </Card>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default Dashboard;
