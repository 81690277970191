import {
  Autocomplete,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

const LocationAmbianceSetupDialog = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  optionsData,
}) => {
  const [options, setOptions] = useState({
    isQuiet: { response: false, reasoning: "" },
    isProneToWinds: { response: false, reasoning: "" },
    isProneToRain: { response: false, reasoning: "" },
    isProneToThunderstorms: { response: false, reasoning: "" },
    hasChattingNoise: { response: false, reasoning: "" },
    hasTrafficNoise: { response: false, reasoning: "" },
    hasIndustryNoise: { response: false, reasoning: "" },
    hasEcho: { response: false, reasoning: "" },
    isOpenLocation: { response: false, reasoning: "" },
  });

  useEffect(() => {
    setOptions(optionsData);
  }, [open, optionsData]);

  const handleConfirmation = e => {
    onContinue(options);
  };

  const handleDialogClose = e => {
    onClose(options);
  };

  const handleSwitchInputChange = e => {
    const { name, checked } = e.target;
    setOptions({ ...options, [name]: { response: checked } });
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="isQuiet"
                id="isQuiet"
                checked={options.isQuiet.response}
                onChange={handleSwitchInputChange}
              />
            }
            label="Is it a quiet location?"
          />
          {options.isQuiet.reasoning &&
            options.isQuiet.reasoning.length > 0 && (
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: "block", ml: 6 }}
              >
                {options.isQuiet.reasoning}
              </Typography>
            )}
          <FormControlLabel
            control={
              <Switch
                name="hasEcho"
                id="hasEcho"
                checked={options.hasEcho.response}
                onChange={handleSwitchInputChange}
              />
            }
            label="Does it have echo?"
          />
          {options.hasEcho.reasoning &&
            options.hasEcho.reasoning.length > 0 && (
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: "block", ml: 6 }}
              >
                {options.hasEcho.reasoning}
              </Typography>
            )}
          <FormControlLabel
            control={
              <Switch
                name="isProneToWinds"
                id="isProneToWinds"
                checked={options.isProneToWinds.response}
                onChange={handleSwitchInputChange}
              />
            }
            label="Is it windy?"
          />
          {options.isProneToWinds.reasoning &&
            options.isProneToWinds.reasoning.length > 0 && (
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: "block", ml: 6 }}
              >
                {options.isProneToWinds.reasoning}
              </Typography>
            )}
          <FormControlLabel
            control={
              <Switch
                name="isProneToRain"
                id="isProneToRain"
                checked={options.isProneToRain.response}
                onChange={handleSwitchInputChange}
              />
            }
            label="Is it rainy?"
          />
          {options.isProneToRain.reasoning &&
            options.isProneToRain.reasoning.length > 0 && (
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: "block", ml: 6 }}
              >
                {options.isProneToRain.reasoning}
              </Typography>
            )}
          <FormControlLabel
            control={
              <Switch
                name="isProneToThunderstorms"
                id="isProneToThunderstorms"
                checked={options.isProneToThunderstorms.response}
                onChange={handleSwitchInputChange}
              />
            }
            label="Are there thunderstorms?"
          />
          {options.isProneToThunderstorms.reasoning &&
            options.isProneToThunderstorms.reasoning.length > 0 && (
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: "block", ml: 6 }}
              >
                {options.isProneToThunderstorms.reasoning}
              </Typography>
            )}
          <FormControlLabel
            control={
              <Switch
                name="hasChattingNoise"
                id="hasChattingNoise"
                checked={options.hasChattingNoise.response}
                onChange={handleSwitchInputChange}
              />
            }
            label="Does it have noise of people chatting around?"
          />
          {options.hasChattingNoise.reasoning &&
            options.hasChattingNoise.reasoning.length > 0 && (
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: "block", ml: 6 }}
              >
                {options.hasChattingNoise.reasoning}
              </Typography>
            )}
          <FormControlLabel
            control={
              <Switch
                name="hasTrafficNoise"
                id="hasTrafficNoise"
                checked={options.hasTrafficNoise.response}
                onChange={handleSwitchInputChange}
              />
            }
            label="Does it have traffic noise?"
          />
          {options.hasTrafficNoise.reasoning &&
            options.hasTrafficNoise.reasoning.length > 0 && (
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: "block", ml: 6 }}
              >
                {options.hasTrafficNoise.reasoning}
              </Typography>
            )}
          <FormControlLabel
            control={
              <Switch
                name="hasIndustryNoise"
                id="hasIndustryNoise"
                checked={options.hasIndustryNoise.response}
                onChange={handleSwitchInputChange}
              />
            }
            label="Does it have industry/factory noise?"
          />
          {options.hasIndustryNoise.reasoning &&
            options.hasIndustryNoise.reasoning.length > 0 && (
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: "block", ml: 6 }}
              >
                {options.hasIndustryNoise.reasoning}
              </Typography>
            )}
          <FormControlLabel
            control={
              <Switch
                name="isOpenLocation"
                id="isOpenLocation"
                checked={options.isOpenLocation.response}
                onChange={handleSwitchInputChange}
              />
            }
            label="Is this an open space location?"
          />
          {options.isOpenLocation.reasoning &&
            options.isOpenLocation.reasoning.length > 0 && (
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: "block", ml: 6 }}
              >
                {options.isOpenLocation.reasoning}
              </Typography>
            )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmation} color="primary">
          Continue
        </Button>
        <Button onClick={handleDialogClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationAmbianceSetupDialog;
