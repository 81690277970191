import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../../Services/auth";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";
import ImageGenerationDialog from "../../UiComponents/ImageGenerationDialog";
import ImagePicker from "../../UiComponents/ImagePicker";

function Book() {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
  };
  const navigate = useNavigate();
  const pageParams = useParams();

  const [bookData, setBookData] = useState({
    uuid: "",
    title: "",
    author: "",
    releaseYear: "",
    description: "",
    coverImage: "",
    newCoverImage: null,
  });
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [creatingNew, setCreatingNew] = useState(false);
  const [openImageGenerationDialog, setOpenImageGenerationDialog] =
    useState(false);
  const [imageGenerationOptions, setImageGenerationOptions] = useState({
    imageService: {
      name: "Microsoft (Azure OpenAI)",
      endpoint: "imageIdeationBasedOnPromptAzure",
    },
    useLocation: true,
    useCharacters: true,
    useImageDescriptorService: true,
  });
  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePickerOptions, setImagePickerOptions] = useState({});

  useEffect(() => {
    if (pageParams.id == "new") {
      setCreatingNew(true);
    }

    //add new or edit?
    //cannot use creatingNew as it won't be set as yet
    if (pageParams.id != "new") {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getBooks`,
          { filters: { bookUuid: pageParams.id } },
          { headers }
        )
        .then(response => {
          log.trace("response.data", response.data);
          log.trace(response.data);

          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.coverImage;
          });

          setBookData(response.data[0]);
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit");

    try {
      e.preventDefault();

      setLoading(true);

      log.trace("bookData");
      log.trace(bookData);

      const formData = new FormData();

      formData.append("userId", firebaseAuth.currentUser.uid);

      if (!creatingNew) {
        formData.append("uuid", bookData.uuid);
      }

      formData.append("title", bookData.title);
      formData.append("author", bookData.author);
      formData.append("description", bookData.description);

      bookData.imagePrompt &&
        formData.append("imagePrompt", bookData.imagePrompt);

      bookData.coverImage && formData.append("coverImage", bookData.coverImage);

      bookData.newCoverImage &&
        formData.append("newCoverImage", bookData.newCoverImage);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "multipart/form-data";

      if (creatingNew) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/putBook`, formData, {
            headers,
          })
          .then(response => {
            log.trace("response.data", response.data);
            log.trace(response.data);

            log.trace("Book data submitted successfully!");
            setBookData({});
            navigate("/console/books");
          })
          .catch(error => {
            log.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        axios
          .put(`${process.env.REACT_APP_API_URL}/putBook`, formData, {
            headers,
          })
          .then(response => {
            log.trace("response.data", response.data);
            log.trace(response.data);

            log.trace("Book data submitted successfully!");
            setBookData({});
            navigate("/console/books");
          })
          .catch(error => {
            log.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setBookData({ ...bookData, [name]: value });
  };

  const handleFileChange = e => {
    log.trace("handleFileChange");
    log.trace(e.target.files[0]);

    URL.revokeObjectURL(bookData.imageUrl);
    const previewImageUrl = URL.createObjectURL(e.target.files[0]);
    log.trace(previewImageUrl);

    setBookData({
      ...bookData,
      newCoverImage: e.target.files[0],
      coverImage: e.target.files[0].name,
      imageUrl: previewImageUrl,
      imagePrompt: null,
    });
  };

  const handleCancel = () => {
    navigate("/console/books");
  };

  const handleBookCoverLoaded = e => {
    const img = e.target;
    log.trace(img.width + "," + img.height);

    //TODO warn of best options for image format, ration, etc
  };

  const suggestCoverImageFromBookDescription = async selectedOptionsData => {
    log.trace("suggestCoverImageFromBookDescription");
    if (!bookData.description || bookData.description.length == 0) {
      setAlertMessage({
        message:
          "Please describe with a few words what the book is all about. The richer the description, the more accurate the suggestion will be.",
        severity: "error", //success info warning error
      });

      return;
    }

    log.trace(selectedOptionsData);

    setAlertMessage({});
    setLoading(true);

    // genai to produce bg image
    try {
      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${selectedOptionsData.imageService.endpoint}`,
          {
            bookUuid: pageParams.id,
            narrativePrompt: bookData.description,
            options: selectedOptionsData,
          },
          { headers }
        )
        .then(response => {
          log.trace("reponse.data");
          log.trace(response.data);

          const imageUrl =
            `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
            response.data.filePath;

          //set form data with image generated. image is already stored, so just save the file path
          setBookData({
            ...bookData,
            imagePrompt: response.data.imagePrompt,
            coverImage: response.data.filePath,
            imageUrl: imageUrl,
          });
        })
        .catch(error => {
          log.error(error);

          if (error.status == 460) {
            setAlertMessage({
              message:
                "Membership generation limits reached. Please upgrade your membership or wait for the next cycle to continue.",
              severity: "error", //success info warning error
            });
          } else {
            setAlertMessage({
              message:
                "Could not generate an image suggestion at this time. Please try again or contact support.",
              severity: "error", //success info warning error
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error generating suggestion", error);
      // Handle the error, e.g., display an error message to the user
    }
  };

  const handleOpenImageGenerationDialog = () => {
    log.trace("handleOpenImageGenerationDialog");

    // TODO how to properly handle no characters or location options
    setImageGenerationOptions({
      ...imageGenerationOptions,
      useCharacters: false,
      useLocation: false,
    });

    setOpenImageGenerationDialog(true);
  };

  const handleCloseImageGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseImageGenerationDialog");
    log.trace(selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);

    setOpenImageGenerationDialog(false);
  };

  const handleContinueImageGeneration = async selectedOptionsData => {
    log.trace("handleContinueImageGeneration");
    log.trace(selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);

    suggestCoverImageFromBookDescription(selectedOptionsData);
  };

  const handleOpenImagePickerDialog = () => {
    setOpenImagePickerDialog(true);
  };

  const handleCloseImagePickerDialog = selectedOptionsData => {
    log.trace("handleCloseImagePickerDialog");
    log.trace(selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);

    setOpenImagePickerDialog(false);
  };

  const handleContinueImagePicker = async selectedOptionsData => {
    log.trace("handleContinueImagePicker");
    log.trace(selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);

    //from computer or from gallery, ...
    if (!selectedOptionsData) {
      log.trace("from computer");
      imagePickerSelectFromComputer();
    } else {
      log.trace("from gallery");
      const filePath = selectedOptionsData.filePath;
      const imagePrompt = selectedOptionsData.imagePrompt;
      const imageUrl = selectedOptionsData.imageUrl;

      //set form data with image previously generated. image is already stored, so just get the file path
      setBookData({
        ...bookData,
        imagePrompt: imagePrompt,
        coverImage: filePath,
        imageUrl: imageUrl,
        newCoverImage: null,
      });
    }
  };

  const imagePickerSelectFromComputer = () => {
    uploadInputRef.current && uploadInputRef.current.click();
  };

  const uploadInputRef = useRef(null);

  return (
    <Box component="section" sx={{}}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <ImageGenerationDialog
            title="Image Generation Options"
            message="Select the options for generating the page background image."
            open={openImageGenerationDialog}
            onClose={handleCloseImageGenerationDialog}
            onContinue={handleContinueImageGeneration}
            optionsData={imageGenerationOptions}
          />

          <ImagePicker
            title="Image Selector"
            message="Select an image from your gallery or from your computer."
            open={openImagePickerDialog}
            onClose={handleCloseImagePickerDialog}
            onContinue={handleContinueImagePicker}
            optionsData={imagePickerOptions}
          />

          <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="book-details-content"
                id="book-details-header"
              >
                Book Details
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                  <TextField
                    id="title"
                    name="title"
                    fullWidth
                    required
                    value={bookData.title}
                    onChange={handleInputChange}
                    label="Title"
                    variant="outlined"
                  />
                  <TextField
                    id="author"
                    name="author"
                    fullWidth
                    required
                    value={bookData.author}
                    onChange={handleInputChange}
                    label="Author"
                    variant="outlined"
                  />
                  <TextField
                    id="description"
                    name="description"
                    fullWidth
                    required
                    value={bookData.description}
                    onChange={handleInputChange}
                    multiline
                    maxRows={20}
                    label="Description"
                    variant="outlined"
                  />
                  <Button
                    variant="text"
                    onClick={handleOpenImageGenerationDialog}
                  >
                    Suggest Cover Image from Text
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="images-content"
                id="images-header"
              >
                Media
              </AccordionSummary>
              <AccordionDetails>
                <Box component="section" sx={{ p: 2 }}>
                  <Button
                    onClick={handleOpenImagePickerDialog}
                    variant="contained"
                  >
                    Select Cover Image
                  </Button>

                  {bookData.coverImage && (
                    <Box>
                      <Avatar
                        alt={bookData.coverImage}
                        src={bookData.imageUrl}
                        variant="square"
                        sx={{ width: "100%", height: "30%" }}
                        onLoad={handleBookCoverLoaded}
                      />
                      <Typography
                        variant="body2"
                        id="coverImage"
                        name="coverImage"
                        sx={{ textAlign: "right" }}
                      >
                        {bookData.coverImage}
                      </Typography>
                    </Box>
                  )}
                  <input
                    id="newCoverImage"
                    name="newCoverImage"
                    ref={uploadInputRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>

            <Stack spacing={2} direction="row" sx={{ p: 2 }}>
              <Button variant="contained" type="submit" color="primary">
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Book;
