import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import firebaseAuth from "./Services/auth";
import { logEvent } from "./Services/firebase";
import log from "./Tools/Log";

function CheckAccountStateInterstitial() {
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    log.trace("checkUserAuthorization");

    const headers = {
      "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
      Authorization: `Bearer ${await firebaseAuth.currentUser.getIdToken()}`,
      "Content-Type": "application/json",
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/checkAuth`, {}, { headers })
      .then(() => {
        navigate("/console");
      })
      .catch(error => {
        log.trace(error);

        if (error.status == 310) {
          // 310 - not a member
          navigate("/newuserprofile");
        } else if (error.status == 311) {
          // 311 - no membership
          navigate("/newusermembership");
        } else if (error.status == 313) {
          // 313 - already subscribed
          navigate("/console"); // TODO check loop cycles
        } else if (error.status == 314) {
          // 314 - session paid; no membership created yet
          navigate("/subscribesuccess");
        } else {
          // server error
          log.error(error);
          firebaseAuth.signOut().then(() => {
            navigate("/");
          });
        }
      });
  };

  return <></>;
}

export default CheckAccountStateInterstitial;
