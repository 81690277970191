import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Slider,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LearningAudioDetails from "../Console/Learning/LearningAudioDetails";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

const AudioGenerationDialog = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  optionsData,
}) => {
  const [options, setOptions] = useState({
    voiceType: "en-US-Journey-F",
    speakingRate: 1,
    pitch: 0,
  });

  useEffect(() => {
    if (Object.keys(optionsData).length > 0) {
      setOptions(optionsData);
    }
  }, [open, optionsData]);

  const handleConfirmation = e => {
    onContinue(options);
  };

  const handleDialogClose = e => {
    onClose(options);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setOptions({ ...options, [name]: value });
  };

  const handleVoiceTypeInputChange = (event, value) => {
    setOptions({ ...options, voiceType: value });
  };

  // TODO fetch from backend; provide meaningful voice aliases
  const voiceTypeList = {
    options: [
      {
        label: "Casual (Male K)",
        name: "en-US-Casual-K",
        gender: "MALE",
        isConfigurable: true,
      },
      {
        label: "Journey (Male D)",
        name: "en-US-Journey-D",
        gender: "MALE",
        isConfigurable: false,
      },
      {
        label: "Polyglot (Male 1)",
        name: "en-US-Polyglot-1",
        gender: "MALE",
        isConfigurable: true,
      },
      {
        label: "Standard (Male B)",
        name: "en-US-Standard-B",
        gender: "MALE",
        isConfigurable: true,
      },
      {
        label: "Studio (Male Q)",
        name: "en-US-Studio-Q",
        gender: "MALE",
        isConfigurable: true,
      },
      {
        label: "Wavenet (Male A)",
        name: "en-US-Wavenet-A",
        gender: "MALE",
        isConfigurable: true,
      },
      {
        label: "Journey (Female F)",
        name: "en-US-Journey-F",
        gender: "FEMALE",
        isConfigurable: false,
      },
      {
        label: "Neural2 (Female C)",
        name: "en-US-Neural2-C",
        gender: "FEMALE",
        isConfigurable: true,
      },
      {
        label: "News (Female K)",
        name: "en-US-News-K",
        gender: "FEMALE",
        isConfigurable: true,
      },
      {
        label: "Studio (Female O)",
        name: "en-US-Studio-O",
        gender: "FEMALE",
        isConfigurable: true,
      },
    ],
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <Autocomplete
          sx={{ p: 2 }}
          {...voiceTypeList}
          id="voiceType"
          name="voiceType"
          fullWidth
          autoComplete
          onChange={handleVoiceTypeInputChange}
          value={options.voiceType}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  variant="filled"
                  label={option.label}
                  key={key}
                  {...tagProps}
                />
              );
            })
          }
          renderInput={voice => (
            <TextField
              {...voice}
              key={voice.name}
              variant="standard"
              label="Voice Type"
            />
          )}
        />
        {options.voiceType.isConfigurable && (
          <>
            <Stack direction="row" spacing={2}>
              <Typography
                variant="p"
                sx={{ textAlign: "right", width: "12em" }}
              >
                Speaking Rate
              </Typography>
              <Slider
                id="speakingRate"
                name="speakingRate"
                value={options.speakingRate}
                onChange={handleInputChange}
                valueLabelDisplay="auto"
                min={0.25}
                max={4.0}
                step={0.05}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography
                variant="p"
                sx={{ textAlign: "right", width: "12em" }}
              >
                Pitch
              </Typography>
              <Slider
                id="pitch"
                name="pitch"
                value={options.pitch}
                onChange={handleInputChange}
                valueLabelDisplay="auto"
                min={-0.2}
                max={0.2}
                step={0.005}
              />
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmation} color="primary">
          Continue
        </Button>
        <Button onClick={handleDialogClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AudioGenerationDialog;
