import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PeopleIcon from "@mui/icons-material/People";
import SchoolIcon from "@mui/icons-material/School";
import TimelineIcon from "@mui/icons-material/Timeline";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { PageContainer } from "@toolpad/core/PageContainer";
import React, { useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

function BookEditorLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const pageParams = useParams();

  const [pathname, setPathname] = useState(location.pathname);

  const router = useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: path => {
        setPathname(String(path));
        navigate(path);
      },
    };
  }, [navigate, pathname]);

  const [bookId, setBookId] = useState(pageParams.id);

  const [branding, setBranding] = useState({
    logo: "",
    title: location.state?.title ?? "LIBELL.US",
  });

  const NAVIGATION = [
    {
      segment: "console/books",
      title: "All Books",
      icon: <ArrowBackIosIcon />,
    },
    {
      kind: "header",
      title: "Home",
    },
    {
      segment: "console/books/" + bookId,
      title: "Overview",
      icon: <DashboardIcon />,
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: "Content",
    },
    {
      segment: "console/books/" + bookId + "/pages",
      title: "Pages",
      icon: <MenuBookIcon />,
    },
    {
      segment: "console/books/" + bookId + "/characters",
      title: "Characters",
      icon: <PeopleIcon />,
    },
    {
      segment: "console/books/" + bookId + "/locations",
      title: "Locations",
      icon: <AddLocationAltIcon />,
    },
    {
      segment: "console/books/" + bookId + "/timelines",
      title: "Timelines",
      icon: <TimelineIcon />,
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: "Help",
    },
    {
      segment: "console/books/" + bookId + "/learningcenter",
      title: "Learning Center",
      icon: <SchoolIcon />,
    },
  ];

  return (
    // preview-start
    <AppProvider navigation={NAVIGATION} router={router} branding={branding}>
      <DashboardLayout>
        <PageContainer>
          <Outlet />
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
    // preview-end
  );
}

export default BookEditorLayout;
