import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../../Services/auth";
import { logEvent } from "../../Services/firebase";
import { truncateIfNeeded } from "../../Tools/DataUtils";
import log from "../../Tools/Log";
import ConfirmationDialog from "../../UiComponents/ConfirmationDialog";

function Characters() {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
  };

  const navigate = useNavigate();
  const pageParams = useParams();

  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogConfirmationData, setDialogConfirmationData] = useState({});
  const [characters, setCharacters] = useState([]);
  const [bookId] = useState(pageParams.id);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    log.trace("handleCloseDialog");
    setOpenDialog(false);
    setDialogConfirmationData({});
  };

  const handleConfirmDialog = async () => {
    log.trace("handleConfirmDialog");
    handleCloseDialog();

    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/deleteCharacter`,
          {
            bookUuid: bookId,
            characterUuid: dialogConfirmationData.uuid,
          },
          { headers }
        )
        .then(response => {
          log.trace("response.data", response.data);
          log.trace(response.data);
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          setDialogConfirmationData({});
          setLoading(false);
          fetchData();
        });
    } catch (error) {
      console.error("Error requesting action:", error);
      setLoading(false);
    }
  };

  const handleCreateClick = () => {
    log.trace("create new character");
    navigate("/console/books/" + bookId + "/characters/new/edit");
  };

  const handleEditClick = (event, characterId) => {
    log.trace("edit book " + bookId + " character " + characterId);
    navigate(
      "/console/books/" + bookId + "/characters/" + characterId + "/edit"
    );
  };

  const handleDeleteClick = async (event, characterId, characterName) => {
    log.trace("confirm delete character " + characterId);

    setDialogConfirmationData({ uuid: characterId, title: characterName });

    handleOpenDialog();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      const filters = {
        //TODO replace with implementation later
        title: "search*",
      };

      log.trace("fetchData with filters");
      log.trace(filters);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getCharacters`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          { headers }
        )
        .then(response => {
          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          setCharacters(response.data);
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          log.trace("fetchData done");
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  return (
    <Box component="section" sx={{}}>
      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <ConfirmationDialog
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmDialog}
            title="Confirm Deletion"
            message="Warning! This action is irreversible. Please type the character name to confirm the action."
            promptHelperText="Type the character name to confirm."
            confirmationData={dialogConfirmationData}
          />
          <Grid
            container
            rowSpacing={1}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid size={3}>
              <Button
                onClick={handleCreateClick}
                variant="text"
                sx={{ width: "100%", height: "100%" }}
              >
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AddCircleOutlineOutlinedIcon />
                  CREATE NEW
                </Stack>
              </Button>
            </Grid>
            {characters.map(character => (
              <Grid size={3} key={character.uuid}>
                <Card
                  sx={{
                    maxWidth: 345,
                    height: 345,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    sx={{ height: 140 }}
                    image={character.imageUrl}
                    title={character.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {character.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      {truncateIfNeeded(character.backstory)}
                    </Typography>
                  </CardContent>

                  <CardActions sx={{ mt: "auto" }}>
                    <IconButton
                      onClick={e => handleEditClick(e, character.uuid)}
                      color="primary"
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={e =>
                        handleDeleteClick(e, character.uuid, character.name)
                      }
                      color="primary"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default Characters;
