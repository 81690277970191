import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";
import "./weather.css";

function PagePreview({ openPreview, pageData, locationData, onClick }) {
  const ref = useRef(null);
  let { scrollYProgress, scrollY } = useScroll({
    container: ref,
  });
  const transformImg = useTransform(scrollYProgress, [0, 1.0], [0, -300]);

  const [playingPreview, setPlayingPreview] = useState(false);
  const [fontFamily, setFontFamily] = useState();
  const [fontSize, setFontSize] = useState();
  const [audioVolume, setAudioVolume] = useState();

  const [pageHasAudio, setPageHasAudio] = useState(false);

  const [isRaining, setIsRaining] = useState(false);
  const [isCrowdTalking, setIsCrowdTalking] = useState(false);

  const [isThunderstorm, setIsThunderstorm] = useState(false);
  const [flashInterval, setFlashInterval] = useState();

  const fontFamilies = {
    options: [
      "Arial, sans-serif", // web safe & fallback
      "Brush Script MT, cursive", // web safe & fallback
      "Copperplate, Papyrus, fantasy", // web safe & fallback
      "Courier New, Courier, monospace", // web safe & fallback
      "Garamond, serif", // web safe & fallback
      "Georgia, serif", // web safe & fallback
      "Helvetica Neue", // other
      "Kalam", //@fontsource
      "Roboto", //@fontsource
      "Tahoma, sans-serif", // web safe & fallback
      "Times New Roman, Times, serif", // web safe & fallback
      "Trebuchet MS, sans-serif", // web safe & fallback
      "Verdana, sans-serif", // web safe & fallback
    ],
  };

  const [rainDownpourAudio] = useState(
    new Audio(
      "https://firebasestorage.googleapis.com/v0/b/libell-us.firebasestorage.app/o/assetsLibrary%2FsoundFx%2Fweather%2FWeather%20Ambience%20Heavy%20Rain%20Downpour%20Splatty%2001.wav?alt=media&token=b9acc13f-32c2-40ec-9aba-228bd7cad63b"
    )
  );
  const [thunderstormAudio] = useState(
    new Audio(
      "https://firebasestorage.googleapis.com/v0/b/libell-us.firebasestorage.app/o/assetsLibrary%2FsoundFx%2Fweather%2FWeather%20Ambience%20Rain%20Thunderstorm%20Thunder%2001.wav?alt=media&token=22742db8-b532-4a8a-875f-6dd26c69b212"
    )
  );
  const [crowdTalkingAudio] = useState(
    new Audio(
      "https://firebasestorage.googleapis.com/v0/b/libell-us.firebasestorage.app/o/assetsLibrary%2FsoundFx%2FhumanElements%2Fcrowd_talking-6762.mp3?alt=media&token=f3a672ae-9fba-40ee-a026-36d39b1c563d"
    )
  );

  useEffect(() => {
    log.trace("PagePreview -> useEffect");
    document.addEventListener("keydown", handleKeyDown);

    // TODO by the gods, refactor this mess
    pageData && pageData.audioUrl && setPageHasAudio(true);
    locationData &&
      locationData.ambiance &&
      locationData.ambiance.isProneToRain.response &&
      setPageHasAudio(true);
    locationData &&
      locationData.ambiance &&
      locationData.ambiance.isProneToThunderstorms.response &&
      setPageHasAudio(true);
    locationData &&
      locationData.ambiance &&
      locationData.ambiance.hasChattingNoise.response &&
      setPageHasAudio(true);

    var play = document.getElementById("narrativeAudio");
    if (play) {
      play.pause();
      play.currentTime = 0;
    }

    setFontFamily("Kalam");
    setFontSize(32);
    setAudioVolume(0.0);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [pageData, locationData]);

  useEffect(() => {
    if (isRaining) {
      setPageHasAudio(true);
      rainDownpourAudio.play();
      rainDownpourAudio.volume = audioVolume * 0.15;
    } else {
      rainDownpourAudio.pause();
      rainDownpourAudio.currentTime = 0;
    }

    renderRain();
  }, [isRaining, audioVolume]);

  useEffect(() => {
    if (isThunderstorm) {
      setPageHasAudio(true);
      thunderstormAudio.play();
      thunderstormAudio.volume = audioVolume * 0.2;
    } else {
      thunderstormAudio.pause();
      thunderstormAudio.currentTime = 0;
    }

    renderThunderstorm();
  }, [isThunderstorm, audioVolume]);

  useEffect(() => {
    if (isCrowdTalking) {
      setPageHasAudio(true);
      crowdTalkingAudio.play();
      crowdTalkingAudio.volume = audioVolume * 0.8;
    } else {
      crowdTalkingAudio.pause();
      crowdTalkingAudio.currentTime = 0;
    }
  }, [isCrowdTalking, audioVolume]);

  const togglePlayPagePreview = e => {
    log.trace("togglePlayPagePreview");

    if (!playingPreview) {
      playPagePreview(e);
    } else {
      stopPagePreview(e);
    }
  };

  const makeItThunder = (state = true) => {
    log.trace("makeItThunder", state);

    if (state) {
      if (
        locationData &&
        locationData.ambiance &&
        locationData.ambiance.isProneToThunderstorms.response
      ) {
        log.trace("thunder! nananananaaaaaa!");
        setIsThunderstorm(true);
      }
    } else {
      clearInterval(flashInterval);
      setIsThunderstorm(false);
    }
  };

  const renderThunderstorm = function () {
    log.trace("renderThunderstorm", isThunderstorm);

    if (!isThunderstorm) return;

    clearInterval(flashInterval);
    setFlashInterval(
      setInterval(() => {
        if (Math.random() < 0.05) {
          flash();
        }
      }, 1000)
    );
  };

  const flash = function () {
    log.trace("flash");
    const lightning = document.querySelector(".lightning");
    const lightningBG = document.querySelector(".lightningBG");
    lightning.style.backgroundColor = "rgba(255, 255, 255, 1.0)";
    lightningBG.style.opacity = 0.95;
    setTimeout(() => {
      lightning.style.backgroundColor = "rgba(255, 255, 255, 0.0)";
      lightningBG.style.opacity = 1.0;
    }, 100);
  };

  const makeItRain = (state = true) => {
    log.trace("makeItRain", state);

    if (state) {
      if (
        locationData &&
        locationData.ambiance &&
        locationData.ambiance.isProneToRain.response
      ) {
        log.trace("raining!");
        setIsRaining(true);
      }
    } else {
      setIsRaining(false);
    }
  };

  /**
   * Renders the rain effect on the page.
   * Clears any existing rain elements and creates new ones.
   */
  const renderRain = function () {
    log.trace("renderRain", isRaining);

    if (!isRaining) return;

    const frontRain = document.getElementById("frontRain");
    const backRain = document.getElementById("backRain");
    if (frontRain) clearRain(frontRain);
    if (backRain) clearRain(backRain);

    const { frontFragment, backFragment } = createRainFragments();

    if (frontRain) frontRain.appendChild(frontFragment);
    if (backRain) backRain.appendChild(backFragment);
  };

  /**
   * Clears all child elements from the given rain container.
   * @param {HTMLElement} rainContainer - The container element to clear.
   */
  const clearRain = rainContainer => {
    log.trace("clearRain");

    while (rainContainer.firstChild) {
      rainContainer.removeChild(rainContainer.firstChild);
    }
  };

  const createRainFragments = () => {
    log.trace("createRainFragments");

    let increment = 0;
    const frontFragment = document.createDocumentFragment();
    const backFragment = document.createDocumentFragment();

    while (increment < 100) {
      const randomHundred = Math.floor(Math.random() * 98 + 1);
      const randomFive = Math.floor(Math.random() * 4 + 2);
      increment += randomFive;

      const { drop, backDrop } = createRainDrop(
        randomHundred,
        randomFive,
        increment
      );

      frontFragment.appendChild(drop);
      backFragment.appendChild(backDrop);
    }

    return { frontFragment, backFragment };
  };

  const createRainDrop = (randomHundred, randomFive, increment) => {
    log.trace("createRainDrop", increment);

    const drop = document.createElement("div");
    drop.className = "drop";
    drop.style.left = increment + randomFive + "%";
    drop.style.bottom = randomFive + randomFive - 1 + 100 + "%";
    drop.style.animationDelay = "0." + randomHundred + "s";
    drop.style.animationDuration = "0.5" + randomHundred + "s";

    const stem = document.createElement("div");
    stem.className = "stem";
    stem.style.animationDelay = "0." + randomHundred + "s";
    stem.style.animationDuration = "0.5" + randomHundred + "s";

    const splat = document.createElement("div");
    splat.className = "splat";
    splat.style.animationDelay = "0." + randomHundred + "s";
    splat.style.animationDuration = "0.5" + randomHundred + "s";

    drop.appendChild(stem);
    drop.appendChild(splat);

    const backDrop = drop.cloneNode(true);
    backDrop.style.right = increment + randomFive + "%";

    return { drop, backDrop };
  };

  const makeItCrowdTalking = (state = true) => {
    log.trace("makeItCrowdTalking", state);

    if (state) {
      if (
        locationData &&
        locationData.ambiance &&
        locationData.ambiance.hasChattingNoise.response
      ) {
        log.trace("chatting!");
        setIsCrowdTalking(true);
      }
    } else {
      setIsCrowdTalking(false);
    }
  };

  const playPagePreview = e => {
    log.trace("playPagePreview");

    logEvent("lib_page_preview", {
      button_name: "play",
      page_name: "PagePreview",
    });

    var play = document.getElementById("narrativeAudio");
    if (play) {
      play.play();
      play.volume = audioVolume;
    }

    makeItRain();
    makeItThunder();
    makeItCrowdTalking();

    setPlayingPreview(true);
  };

  const stopPagePreview = e => {
    log.trace("stopPagePreview");

    var play = document.getElementById("narrativeAudio");
    if (play) {
      play.pause();
      play.currentTime = 0;
    }

    makeItRain(false);
    makeItThunder(false);
    makeItCrowdTalking(false);

    setPlayingPreview(false);
  };

  const closePreview = e => {
    log.trace("closePreview");

    stopPagePreview();
    onClick();
  };

  const handleFontInputChange = (e, value) => {
    log.trace("handleFontInputChange", value);

    setFontFamily(value);
  };

  const changeTextSize = increment => {
    log.trace("changeTextSize", increment);

    setFontSize(fontSize + increment);
  };

  const togglePreviewAudioVolume = e => {
    log.trace("togglePreviewAudioVolume");

    let newVolume = 0.0;
    if (audioVolume <= 0.0) {
      newVolume = 0.5;
    }

    setAudioVolume(newVolume);

    var play = document.getElementById("narrativeAudio");
    if (play) {
      play.volume = newVolume;
    }
  };

  const handleKeyDown = e => {
    log.trace("handleKeyDown", e.key);

    if (e.key === "Escape") {
      closePreview();
    }
  };

  return (
    <>
      <Box
        id="container"
        sx={{
          position: "absolute",
          width: "1000px",
          height: "900px",
          overflowX: "hidden",
          overflowY: "hidden",
          backgroundColor: "#111111",
          borderRadius: 4,
        }}
      >
        {isRaining && (
          <>
            <div id="frontRain" className="rain front-row"></div>
            <div id="backRain" className="rain back-row"></div>
          </>
        )}
        {playingPreview || (
          <Box
            display="flex"
            sx={{
              backgroundColor: "#111111",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography variant="h6" sx={{ zIndex: 12, m: "auto" }}>
              Press{" "}
              <IconButton
                size="large"
                aria-label="play"
                onClick={togglePlayPagePreview}
              >
                <PlayCircleOutlineIcon fontSize="inherit" />
              </IconButton>{" "}
              to start.
            </Typography>
          </Box>
        )}

        {playingPreview ? (
          <motion.div
            initial={{ opacity: 0.0 }}
            animate={{
              opacity: 1,
              transition: { type: "easeInOut", duration: 1.0 },
            }}
            exit={{ opacity: 0, scale: 1.1 }}
            style={{ y: transformImg }}
          >
            <Box
              class="lightning"
              sx={{
                position: "absolute",
              }}
            >
              <img className="lightningBG" src={pageData.imageUrl} />
            </Box>
          </motion.div>
        ) : null}

        <Box
          id="scrollContainer"
          ref={ref}
          sx={{
            zIndex: 5,
            position: "absolute",
            top: "0",
            width: "1000px",
            height: "900px",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 11,
              backgroundColor: "#222222DD",
            }}
          >
            <Stack direction="column" spacing={0}>
              <motion.div
                style={{
                  top: 0,
                  zIndex: 10,
                  width: "100%",
                  height: "3px",
                  backgroundColor: "#eb4034",
                  scaleX: scrollYProgress,
                }}
              ></motion.div>

              <Stack direction="row" spacing={2}>
                <IconButton
                  size="large"
                  aria-label="play"
                  onClick={togglePlayPagePreview}
                >
                  {playingPreview ? (
                    <StopCircleIcon fontSize="inherit" />
                  ) : (
                    <PlayCircleOutlineIcon fontSize="inherit" />
                  )}
                </IconButton>

                {pageHasAudio && (
                  <IconButton
                    size="large"
                    aria-label="play"
                    onClick={togglePreviewAudioVolume}
                  >
                    {audioVolume ? (
                      <VolumeUpIcon fontSize="inherit" />
                    ) : (
                      <VolumeOffIcon fontSize="inherit" />
                    )}
                  </IconButton>
                )}

                {pageData && pageData.audioUrl && (
                  <>
                    <audio
                      id="narrativeAudio"
                      name="narrativeAudio"
                      src={pageData.audioUrl}
                    ></audio>
                  </>
                )}

                <IconButton
                  size="large"
                  aria-label="close"
                  onClick={e => changeTextSize(-5)}
                >
                  <TextDecreaseIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="close"
                  onClick={e => changeTextSize(+5)}
                >
                  <TextIncreaseIcon fontSize="inherit" />
                </IconButton>

                <Autocomplete
                  {...fontFamilies}
                  id="font-family-auto-complete"
                  name="font-family-auto-complete"
                  autoComplete
                  sx={{ width: 300 }}
                  onChange={handleFontInputChange}
                  value={fontFamily}
                  renderInput={params => (
                    <TextField {...params} variant="standard" label="Font" />
                  )}
                />

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ flexGrow: 1 }}
                >
                  <IconButton
                    size="large"
                    aria-label="close"
                    onClick={closePreview}
                    sx={{ ml: "auto" }}
                  >
                    <HighlightOffIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </Stack>
            </Stack>
          </Box>

          <Box
            sx={{
              position: "relative",
              top: "50%",
            }}
          >
            <AnimatePresence>
              {playingPreview ? (
                <>
                  <motion.div
                    initial={{ scale: 0.95, opacity: 0.0 }}
                    animate={{
                      scale: 1,
                      opacity: 1,
                      transition: {
                        type: "easeInOut",
                        duration: 1,
                        delay: 1.5,
                      },
                    }}
                    exit={{ opacity: 0, scale: 1.1 }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        p: 2,
                        whiteSpace: "pre-wrap",
                        fontFamily: fontFamily,
                        fontSize: fontSize,
                        background:
                          "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,1.0))",
                      }}
                    >
                      {pageData.narrative}
                    </Typography>
                  </motion.div>
                </>
              ) : null}
            </AnimatePresence>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default PagePreview;
