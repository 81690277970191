import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  inMemoryPersistence,
  OAuthProvider,
  setPersistence,
} from "firebase/auth";
import { firebaseApp } from "./firebase";

const firebaseAuth = getAuth(firebaseApp);
setPersistence(firebaseAuth, browserLocalPersistence);

// const microsoftAuthProvider = new OAuthProvider('microsoft.com');
// microsoftAuthProvider.setCustomParameters({
//     provider: 'microsoft.com',
//     loginHintKey: 'login_hint'
// });

export default firebaseAuth;
