import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Item,
  Menu,
  Separator,
  Submenu,
  useContextMenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../../Services/auth";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";
import AudioGenerationDialog from "../../UiComponents/AudioGenerationDialog";
import ImageGenerationDialog from "../../UiComponents/ImageGenerationDialog";
import ImagePicker from "../../UiComponents/ImagePicker";
import PagePreview from "./PagePreview";

function Page() {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
  };
  const navigate = useNavigate();
  const pageParams = useParams();

  const [pageData, setPageData] = useState({
    uuid: "",
    bookUuid: "",
    pageNumber: "",
    ideation: "",
    narrative: "",
    location: "",
    characters: [],
    narrativeAudio: null,
    backgroundImage: "",
    newBackgroundImage: null,
  });
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [creatingNew, setCreatingNew] = useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [charactersList, setCharactersList] = useState({ options: [] });
  const [locationsList, setLocationsList] = useState({ options: [] });

  const [openImageGenerationDialog, setOpenImageGenerationDialog] =
    useState(false);
  const [imageGenerationOptions, setImageGenerationOptions] = useState({
    imageService: {
      name: "Microsoft (Azure OpenAI)",
      endpoint: "imageIdeationBasedOnPromptAzure",
    },
    useLocation: true,
    useCharacters: true,
    useImageDescriptorService: true,
  });

  const [openAudioGenerationDialog, setOpenAudioGenerationDialog] =
    useState(false);
  const [audioGenerationOptions, setAudioGenerationOptions] = useState({
    voiceType: {
      label: "Studio (Male Q)",
      name: "en-US-Studio-Q",
      gender: "MALE",
      isConfigurable: true,
    },
    speakingRate: 1,
    pitch: 0,
  });

  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePickerOptions, setImagePickerOptions] = useState({});

  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const CONTEXT_MENU_ID = "editor-context-menu";
  const { show } = useContextMenu({
    id: CONTEXT_MENU_ID,
  });

  useEffect(() => {
    log.trace("Page->useEffect", pageParams);

    if (pageParams.pid == "new") {
      setCreatingNew(true);
      setPageData({ ...pageData, bookUuid: pageParams.id });
    }

    fetchData(pageParams.pid == "new");
  }, []);

  const fetchData = async isNewPage => {
    log.trace("fetchData for", pageParams.pid);

    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      const filters = { pageUuid: pageParams.pid };

      // load page if not creating a new one
      if (!isNewPage) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/getPages`,
            {
              bookUuid: pageParams.id,
              filters,
            },
            { headers }
          )
          .then(response => {
            log.trace("response.data", response.data);

            response.data.map(item => {
              item.backgroundImage &&
                (item.imageUrl =
                  `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                  item.backgroundImage);

              item.narrativeAudio &&
                (item.audioUrl =
                  `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
                  item.narrativeAudio);
            });

            if (response.data[0]) {
              if (!response.data[0].characters) {
                response.data[0].characters = [];
              }

              setPageData(response.data[0]);
            }
          })
          .catch(error => {
            log.error(error);
          })
          .finally(() => {
            // setLoading(false);
          });
      }

      // load characters multiple select
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getCharacters`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          { headers }
        )
        .then(response => {
          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          log.trace("response.data", response.data);

          if (response.data && response.data.length > 0) {
            setCharactersList({
              characters: response.data,
              options: response.data.map(item => item.name),
            });
          } else {
            log.trace("useCharacters false");
            setImageGenerationOptions({
              ...imageGenerationOptions,
              useCharacters: false,
            });
          }
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          log.trace("fetchData done");
          // setLoading(false);
        });

      // load locations select
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getLocations`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          { headers }
        )
        .then(response => {
          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          log.trace("response.data", response.data);

          if (response.data && response.data.length > 0) {
            setLocationsList({
              locations: response.data,
              options: response.data.map(item => item.name),
            });
          } else {
            log.trace("useLocation false");
            setImageGenerationOptions({
              ...imageGenerationOptions,
              useLocation: false,
            });
          }
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          log.trace("fetchData done");
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleFormSubmit = async e => {
    log.trace("handleFormSubmit");
    try {
      e.preventDefault();

      setLoading(true);

      log.trace("pageData", pageData);

      const formData = new FormData();

      if (!creatingNew) {
        formData.append("uuid", pageData.uuid);
      }
      formData.append("bookUuid", pageParams.id);
      formData.append("ideation", pageData.ideation);
      formData.append("narrative", pageData.narrative);
      formData.append("characters", pageData.characters ?? []);

      pageData.imagePrompt &&
        formData.append("imagePrompt", pageData.imagePrompt);

      pageData.pageNumber && formData.append("pageNumber", pageData.pageNumber);

      pageData.location && formData.append("location", pageData.location);

      pageData.narrativeAudio &&
        formData.append("narrativeAudio", pageData.narrativeAudio);

      pageData.audioVoiceType &&
        formData.append(
          "audioVoiceType",
          JSON.stringify(pageData.audioVoiceType)
        );

      pageData.backgroundImage &&
        formData.append("backgroundImage", pageData.backgroundImage);

      pageData.newBackgroundImage &&
        formData.append("newBackgroundImage", pageData.newBackgroundImage);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "multipart/form-data";

      if (creatingNew) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/putPage`, formData, {
            headers,
          })
          .then(response => {
            log.trace("response.data", response.data);

            log.trace("Page data submitted successfully!");
            setPageData({});
            navigate(`/console/books/${pageParams.id}/pages`);
          })
          .catch(error => {
            log.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        axios
          .put(`${process.env.REACT_APP_API_URL}/putPage`, formData, {
            headers,
          })
          .then(response => {
            log.trace("response.data", response.data);

            log.trace("Page data submitted successfully!");
            setPageData({});
            navigate(`/console/books/${pageParams.id}/pages`);
          })
          .catch(error => {
            log.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleInputChange = e => {
    log.trace("handleInputChange", e.target);
    const { name, value } = e.target;
    setPageData({ ...pageData, [name]: value });
  };

  const handleFileChange = e => {
    log.trace("handleFileChange", e.target.files[0]);

    if (e.target.files[0]) {
      URL.revokeObjectURL(pageData.imageUrl);
      const previewImageUrl = URL.createObjectURL(e.target.files[0]);
      log.trace(previewImageUrl);
      setPageData({
        ...pageData,
        newBackgroundImage: e.target.files[0],
        backgroundImage: e.target.files[0].name,
        imageUrl: previewImageUrl,
        imagePrompt: null,
      });
    }
  };

  const handleCancel = () => {
    navigate(`/console/books/${pageParams.id}/pages`);
  };

  const handlePageBackgroundImageLoaded = e => {
    const img = e.target;
    log.trace("width x height", img.width, img.height);

    //TODO warn of best options for image format, ration, etc?
    // BE uses sharp; converts to web friendly format automatically
  };

  const suggestPageNarrativeFromIdeation = async () => {
    log.trace("suggestPageNarrativeFromIdeation");

    if (!pageData.ideation || pageData.ideation.length == 0) {
      setAlertMessage({
        message:
          "Please describe the ideation plot for this page with a few words. The richer the description, the more accurate the suggestion will be.",
        severity: "error", //success info warning error
      });

      return;
    }

    setAlertMessage({});
    setLoading(true);

    try {
      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      //genai to produce suggested text
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/suggestNarrativeBasedOnIdeation`,
          {
            bookUuid: pageParams.id,
            ideationPrompt: pageData.ideation,
            location: pageData.location,
            characters: pageData.characters,
          },
          { headers }
        )
        .then(generatedText => {
          log.trace("generatedText", generatedText.data.text);

          setPageData({ ...pageData, narrative: generatedText.data.text });
        })
        .catch(error => {
          log.error(error);

          if (error.status == 460) {
            setAlertMessage({
              message:
                "Membership generation limits reached. Please upgrade your membership or wait for the next cycle to continue.",
              severity: "error", //success info warning error
            });
          } else {
            setAlertMessage({
              message:
                "Could not generate a narrative suggestion at this time. Please try again or contact support.",
              severity: "error", //success info warning error
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error generating suggestion", error);
      // Handle the error, e.g., display an error message to the user
    }
  };

  const suggestBackgroundImageFromPageNarrative = async selectedOptionsData => {
    log.trace("suggestBackgroundImageFromPageNarrative", selectedOptionsData);
    if (
      (!pageData.ideation || pageData.ideation.length == 0) &&
      (!pageData.narrative || pageData.narrative.length == 0)
    ) {
      setAlertMessage({
        message:
          "Please describe the ideation plot or add content to the narrative for this page. The richer the content, the more accurate the suggestion will be.",
        severity: "error", //success info warning error
      });

      return;
    }

    setAlertMessage({});
    setLoading(true);

    // genai to produce bg image
    try {
      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${selectedOptionsData.imageService.endpoint}`,
          {
            bookUuid: pageParams.id,
            narrativePrompt: pageData.narrative ?? pageData.ideation,
            location: pageData.location,
            characters: pageData.characters,
            options: selectedOptionsData,
          },
          { headers }
        )
        .then(response => {
          log.trace("response.data", response.data);

          const imageUrl =
            `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
            response.data.filePath;

          //set form data with image generated. image is already stored, so just save the file path
          setPageData({
            ...pageData,
            imagePrompt: response.data.imagePrompt,
            backgroundImage: response.data.filePath,
            imageUrl: imageUrl,
          });
        })
        .catch(error => {
          log.error(error);

          if (error.status == 460) {
            setAlertMessage({
              message:
                "Membership generation limits reached. Please upgrade your membership or wait for the next cycle to continue.",
              severity: "error", //success info warning error
            });
          } else {
            setAlertMessage({
              message:
                "Could not generate an image suggestion at this time. Please try again or contact support.",
              severity: "error", //success info warning error
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error generating image", error);
      // Handle the error, e.g., display an error message to the user
    }
  };

  const suggestPageNarrationAudio = async selectedOptionsData => {
    log.trace("suggestPageNarrationAudio");
    try {
      if (!pageData.narrative || pageData.narrative.length == 0) {
        setAlertMessage({
          message:
            "Please add content to the narrative for this page. The audio generation will be based on the full content of the narrative.",
          severity: "error", //success info warning error
        });

        return;
      }

      setAlertMessage({});
      setLoading(true);

      // genai to produce bg image
      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/suggestNarrationBasedOnNarrative`,
          {
            bookUuid: pageParams.id,
            narrativePrompt: pageData.narrative,
            options: selectedOptionsData,
          },
          { headers }
        )
        .then(generatedAudioFilePath => {
          log.trace("generatedAudioFilePath", generatedAudioFilePath.data);

          const audioUrl =
            `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
            generatedAudioFilePath.data;

          //set form data with image generated. image is already stored, so just save the file path
          setPageData({
            ...pageData,
            narrativeAudio: generatedAudioFilePath.data,
            audioUrl: audioUrl,
            audioVoiceType: selectedOptionsData.voiceType,
          });
        })
        .catch(error => {
          log.error(error);

          if (error.status == 460) {
            setAlertMessage({
              message:
                "Membership generation limits reached. Please upgrade your membership or wait for the next cycle to continue.",
              severity: "error", //success info warning error
            });
          } else {
            setAlertMessage({
              message:
                "Could not generate an audio narration at this time. Please try again or contact support.",
              severity: "error", //success info warning error
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error generating audio", error);
      // Handle the error, e.g., display an error message to the user
    }
  };

  const removePageNarrationAudio = async () => {
    log.trace("removePageNarrationAudio");
    setPageData({ ...pageData, narrativeAudio: null, audioUrl: null });
  };

  const showPagePreview = async (state = true) => {
    log.trace("showPagePreview");
    setOpenPreview(state);
  };

  const handleCharacterInputChange = (event, value) => {
    log.trace("handleCharacterInputChange", value);

    setAlertMessage({});
    setPageData({ ...pageData, characters: value });
  };

  const handleCharacterClick = (event, value) => {
    log.trace("handleCharacterClick", value);
  };

  const handleLocationInputChange = (event, value) => {
    log.trace("handleLocationInputChange", value);

    setAlertMessage({});
    setPageData({ ...pageData, location: value });
  };

  const handleLocationClick = (event, value) => {
    log.trace("handleLocationClick", value);
  };

  const handleOpenImageGenerationDialog = (e, selectedOptionsData) => {
    log.trace("handleOpenImageGenerationDialog", selectedOptionsData);

    // TODO how to properly handle no characters or location options
    const optionsSource = selectedOptionsData
      ? selectedOptionsData
      : imageGenerationOptions;

    setImageGenerationOptions({
      ...optionsSource,
      useCharacters: pageData.characters.length != 0,
      useLocation:
        (pageData.location && pageData.location.length != 0) || false,
      useContext: selectedOptionsData ? selectedOptionsData.useContext : false,
      context: selectedOptionsData ? selectedOptionsData.context : null,
    });

    setOpenImageGenerationDialog(true);
  };

  const handleCloseImageGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseImageGenerationDialog", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);
  };

  const handleContinueImageGeneration = async selectedOptionsData => {
    log.trace("handleContinueImageGeneration", selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);

    suggestBackgroundImageFromPageNarrative(selectedOptionsData);
  };

  const handleOpenAudioGenerationDialog = () => {
    log.trace("handleOpenAudioGenerationDialog");
    setOpenAudioGenerationDialog(true);
  };

  const handleCloseAudioGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseAudioGenerationDialog", selectedOptionsData);
    setAudioGenerationOptions(selectedOptionsData);

    setOpenAudioGenerationDialog(false);
  };

  const handleContinueAudioGeneration = async selectedOptionsData => {
    log.trace("handleContinueAudioGeneration", selectedOptionsData);
    setAudioGenerationOptions(selectedOptionsData);
    setOpenAudioGenerationDialog(false);
    suggestPageNarrationAudio(selectedOptionsData);
  };

  const handleOpenImagePickerDialog = () => {
    log.trace("handleOpenImagePickerDialog");
    setOpenImagePickerDialog(true);
  };

  const handleCloseImagePickerDialog = selectedOptionsData => {
    log.trace("handleCloseImagePickerDialog", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);
  };

  const handleContinueImagePicker = async selectedOptionsData => {
    log.trace("handleContinueImagePicker", selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);

    //from computer or from gallery, ...
    if (!selectedOptionsData) {
      log.trace("from computer");
      imagePickerSelectFromComputer();
    } else {
      log.trace("from gallery");
      const filePath = selectedOptionsData.filePath;
      const imagePrompt = selectedOptionsData.imagePrompt;
      const imageUrl = selectedOptionsData.imageUrl;

      //set form data with image previously generated. image is already stored, so just get the file path
      setPageData({
        ...pageData,
        imagePrompt: imagePrompt,
        backgroundImage: filePath,
        imageUrl: imageUrl,
        newBackgroundImage: null,
      });
    }
  };

  const imagePickerSelectFromComputer = () => {
    log.trace("imagePickerSelectFromComputer");

    uploadInputRef.current && uploadInputRef.current.click();
  };

  function handleContextMenu(e) {
    log.trace("handleContextMenu");

    show({
      event: e,
    });
  }

  const handleItemClick = action => {
    log.trace("handleItemClick", action);

    const selection = window.getSelection().toString();
    if (selection.length === 0) {
      return;
    }

    if (action === "suggestImage") {
      const options = {
        ...imageGenerationOptions,
        useContext: true,
        context: selection,
      };
      handleOpenImageGenerationDialog(null, options);
    } else if (action === "rephrase") {
      // TODO
    }
  };

  const uploadInputRef = useRef(null);

  return (
    <Box component="section" sx={{}}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <Backdrop
            sx={theme => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
            open={openPreview}
          >
            <PagePreview
              openPreview={openPreview}
              pageData={pageData}
              locationData={
                (pageData.location &&
                  locationsList.locations.filter(
                    location => location.name === pageData.location
                  )[0]) ||
                null
              }
              onClick={() => showPagePreview(false)}
            />
          </Backdrop>

          <ImageGenerationDialog
            title="Image Generation Options"
            message="Select the options for generating the page background image."
            open={openImageGenerationDialog}
            onClose={handleCloseImageGenerationDialog}
            onContinue={handleContinueImageGeneration}
            optionsData={imageGenerationOptions}
          />

          <AudioGenerationDialog
            title="Audio Generation Options"
            message="Select the options for generating the page narration."
            open={openAudioGenerationDialog}
            onClose={handleCloseAudioGenerationDialog}
            onContinue={handleContinueAudioGeneration}
            optionsData={audioGenerationOptions}
          />

          <ImagePicker
            title="Image Selector"
            message="Select an image from your gallery or from your computer."
            open={openImagePickerDialog}
            onClose={handleCloseImagePickerDialog}
            onContinue={handleContinueImagePicker}
            optionsData={imagePickerOptions}
          />

          <Menu id={CONTEXT_MENU_ID}>
            <Submenu label="Image">
              <Item onClick={e => handleItemClick("suggestImage")}>
                Suggest with text
              </Item>
            </Submenu>
            <Separator />
          </Menu>

          <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="plot-content"
                id="plot-header"
              >
                Plot & Narrative
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2} direction="row" sx={{ p: 2 }}>
                  <Stack spacing={2} direction="column" sx={{ width: "50%" }}>
                    <Autocomplete
                      {...charactersList}
                      id="characters-auto-complete"
                      name="characters-auto-complete"
                      fullWidth
                      autoComplete
                      multiple
                      filterSelectedOptions
                      onChange={handleCharacterInputChange}
                      value={pageData.characters}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              variant="filled"
                              label={option}
                              key={key}
                              onClick={e => handleCharacterClick(e, option)}
                              {...tagProps}
                            />
                          );
                        })
                      }
                      renderInput={character => (
                        <TextField
                          {...character}
                          key={character}
                          variant="standard"
                          label="Characters in Scene"
                        />
                      )}
                    />
                    <Autocomplete
                      {...locationsList}
                      id="location-auto-complete"
                      name="location-auto-complete"
                      fullWidth
                      autoComplete
                      filterSelectedOptions
                      onChange={handleLocationInputChange}
                      value={pageData.location}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              variant="filled"
                              label={option}
                              key={key}
                              onClick={e => handleLocationClick(e, option)}
                              {...tagProps}
                            />
                          );
                        })
                      }
                      renderInput={location => (
                        <TextField
                          {...location}
                          key={location}
                          variant="standard"
                          label="Scene location"
                        />
                      )}
                    />
                    <TextField
                      id="ideation"
                      name="ideation"
                      required
                      autoFocus
                      fullWidth
                      multiline
                      maxRows={20}
                      value={pageData.ideation}
                      onChange={handleInputChange}
                      label="Ideation"
                      variant="outlined"
                    />
                    <Button
                      variant="text"
                      onClick={suggestPageNarrativeFromIdeation}
                      startIcon={<ArticleIcon />}
                    >
                      Suggest Text From Ideation
                    </Button>
                  </Stack>
                  <Stack spacing={2} direction="column" sx={{ width: "50%" }}>
                    <TextField
                      id="narrative"
                      name="narrative"
                      multiline
                      fullWidth
                      maxRows={20}
                      value={pageData.narrative}
                      onChange={handleInputChange}
                      label="Narrative"
                      variant="outlined"
                      onContextMenu={handleContextMenu}
                    />
                    <Button
                      variant="text"
                      onClick={handleOpenImageGenerationDialog}
                      startIcon={<AddPhotoAlternateIcon />}
                    >
                      Suggest Background Image from Text
                    </Button>
                    {pageData.audioUrl ? (
                      <Stack direction="row">
                        <Box
                          component="section"
                          sx={{ p: 1, textAlign: "center" }}
                        >
                          <audio
                            id="narrativeAudio"
                            name="narrativeAudio"
                            controls
                            src={pageData.audioUrl}
                          >
                            Your browser does not support the audio element.
                          </audio>
                        </Box>
                        <IconButton
                          aria-label="Delete audio"
                          color="error"
                          onClick={removePageNarrationAudio}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Stack>
                    ) : (
                      <Button
                        variant="text"
                        onClick={handleOpenAudioGenerationDialog}
                        startIcon={<VolumeUpIcon />}
                      >
                        Generate Audio Narration
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="images-content"
                id="images-header"
              >
                Media
              </AccordionSummary>
              <AccordionDetails>
                <Box component="section" sx={{ p: 2 }}>
                  <Button
                    onClick={handleOpenImagePickerDialog}
                    variant="contained"
                  >
                    Select Background Image
                  </Button>

                  {pageData.backgroundImage && (
                    <Box sx={{ pt: 2, pb: 2 }}>
                      <Avatar
                        alt={pageData.backgroundImage}
                        src={pageData.imageUrl}
                        variant="square"
                        sx={{ width: "100%", height: "30%" }}
                        onLoad={handlePageBackgroundImageLoaded}
                      />
                      <Typography
                        variant="body2"
                        id="backgroundImage"
                        name="backgroundImage"
                        sx={{ textAlign: "right" }}
                      >
                        {pageData.backgroundImage}
                      </Typography>
                    </Box>
                  )}
                  <input
                    id="newBackgroundImage"
                    name="newBackgroundImage"
                    ref={uploadInputRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>

            <Stack spacing={2} direction="row" sx={{ p: 2 }}>
              <Button variant="contained" type="submit" color="primary">
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="text"
                onClick={showPagePreview}
                sx={{ ml: "auto" }}
              >
                Quick Preview
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Page;
