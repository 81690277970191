import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AirIcon from "@mui/icons-material/Air";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../../Services/auth";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";
import ImageGenerationDialog from "../../UiComponents/ImageGenerationDialog";
import ImagePicker from "../../UiComponents/ImagePicker";
import LocationAmbianceSetupDialog from "../../UiComponents/LocationAmbianceSetupDialog";

function Location() {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
  };
  const navigate = useNavigate();
  const pageParams = useParams();

  const [locationData, setLocationData] = useState({
    uuid: "",
    bookUuid: "",
    name: "",
    backstory: "",
    weather: null,
    sounds: null,
    lights: null,
    smells: null,
    sights: null,
    backgroundImage: "",
    newBackgroundImage: null,
  });
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [creatingNew, setCreatingNew] = useState(false);
  const [openImageGenerationDialog, setOpenImageGenerationDialog] =
    useState(false);
  const [imageGenerationOptions, setImageGenerationOptions] = useState({
    imageService: {
      name: "Microsoft (Azure OpenAI)",
      endpoint: "imageIdeationBasedOnPromptAzure",
    },
    useLocation: true,
    useCharacters: true,
    useImageDescriptorService: true,
  });
  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePickerOptions, setImagePickerOptions] = useState({});

  const [openLocationAmbianceSetupDialog, setOpenLocationAmbianceSetupDialog] =
    useState(false);
  const [locationAmbianceOptions, setLocationAmbianceOptions] = useState({
    isQuiet: { response: false, reasoning: "" },
    isProneToWinds: { response: false, reasoning: "" },
    isProneToRain: { response: false, reasoning: "" },
    isProneToThunderstorms: { response: false, reasoning: "" },
    hasChattingNoise: { response: false, reasoning: "" },
    hasTrafficNoise: { response: false, reasoning: "" },
    hasIndustryNoise: { response: false, reasoning: "" },
    hasEcho: { response: false, reasoning: "" },
    isOpenLocation: { response: false, reasoning: "" },
  });

  useEffect(() => {
    if (pageParams.lid == "new") {
      setCreatingNew(true);
      setLocationData({ ...locationData, bookUuid: pageParams.id });
    }

    //add new or edit?
    //cannot use creatingNew as it won't be set as yet
    if (pageParams.lid != "new") {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    log.trace("fetchData for " + pageParams.lid);

    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      const filters = { locationUuid: pageParams.lid };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getLocations`,
          {
            bookUuid: pageParams.id,
            filters,
          },
          { headers }
        )
        .then(response => {
          log.trace("response.data", response.data);
          log.trace(response.data);

          response.data.map(item => {
            item.imageUrl =
              `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
              item.backgroundImage;
          });

          if (response.data[0]) {
            setLocationData(response.data[0]);
            response.data[0].ambiance &&
              setLocationAmbianceOptions(response.data[0].ambiance);
          }
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleFormSubmit = async e => {
    try {
      e.preventDefault();

      setLoading(true);

      log.trace("locationData");
      log.trace(locationData);

      const formData = new FormData();

      if (!creatingNew) {
        formData.append("uuid", locationData.uuid);
      }
      formData.append("bookUuid", pageParams.id);
      formData.append("name", locationData.name);
      formData.append("backstory", locationData.backstory);

      locationData.sights && formData.append("sights", locationData.sights);
      locationData.weather && formData.append("weather", locationData.weather);
      locationData.smells && formData.append("smells", locationData.smells);
      locationData.sounds && formData.append("sounds", locationData.sounds);
      locationData.lights && formData.append("lights", locationData.lights);

      locationAmbianceOptions &&
        formData.append("ambiance", JSON.stringify(locationAmbianceOptions));

      locationData.imagePrompt &&
        formData.append("imagePrompt", locationData.imagePrompt);

      locationData.backgroundImage &&
        formData.append("backgroundImage", locationData.backgroundImage);

      locationData.newBackgroundImage &&
        formData.append("newBackgroundImage", locationData.newBackgroundImage);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "multipart/form-data";

      if (creatingNew) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/putLocation`, formData, {
            headers,
          })
          .then(response => {
            log.trace("response.data", response.data);
            log.trace(response.data);

            log.trace("Location data submitted successfully!");
            setLocationData({});
            navigate(`/console/books/${pageParams.id}/locations`);
          })
          .catch(error => {
            log.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        axios
          .put(`${process.env.REACT_APP_API_URL}/putLocation`, formData, {
            headers,
          })
          .then(response => {
            log.trace("response.data", response.data);
            log.trace(response.data);

            log.trace("Location data submitted successfully!");
            setLocationData({});
            navigate(`/console/books/${pageParams.id}/locations`);
          })
          .catch(error => {
            log.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setLocationData({ ...locationData, [name]: value });
  };

  const handleFileChange = e => {
    log.trace("handleFileChange");
    log.trace(e.target.files[0]);

    if (e.target.files[0]) {
      URL.revokeObjectURL(locationData.imageUrl);
      const previewImageUrl = URL.createObjectURL(e.target.files[0]);
      log.trace(previewImageUrl);
      setLocationData({
        ...locationData,
        newBackgroundImage: e.target.files[0],
        backgroundImage: e.target.files[0].name,
        imageUrl: previewImageUrl,
        imagePrompt: null,
      });
    }
  };

  const handleCancel = () => {
    navigate(`/console/books/${pageParams.id}/locations`);
  };

  const handlePageBackgroundImageLoaded = e => {
    const img = e.target;
    log.trace(img.width + "," + img.height);

    //TODO warn of best options for image format, ration, etc
  };

  // const handleWeatherInputChange = e => {
  //   const { name, value } = e.target;
  //   const weather = locationData.weather;
  //   weather[name] = value;
  //   setLocationData({ ...locationData, weather: weather });
  // };

  const suggestBackgroundImageFromDescription = async selectedOptionsData => {
    log.trace("suggestBackgroundImageFromDescription");
    if (!locationData.backstory || locationData.backstory.length == 0) {
      setAlertMessage({
        message:
          "Please describe the location details. The richer the description, the more accurate the suggestion will be.",
        severity: "error", //success info warning error
      });

      return;
    }

    log.trace(selectedOptionsData);

    setAlertMessage({});
    setLoading(true);

    // TODO this should be a specific endpoint for this functionality; no client side logic
    // genai to produce bg image
    try {
      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      const locationlDescription = [
        "Generate a landscape image for a location with the following characteristics. ",
      ];

      locationData.backstory &&
        locationlDescription.push(locationData.backstory + ".");
      locationData.sights &&
        locationlDescription.push(
          "With the following landmarks: " + locationData.sights + "."
        );
      locationData.weather &&
        locationlDescription.push(
          "With the weather elements: " + locationData.weather + "."
        );
      locationlDescription.push(
        "With sounds and noise as: " + locationData.sounds + "."
      );
      locationlDescription.push(
        "With smells like: " + locationData.smells + "."
      );
      locationlDescription.push(
        "With light setup as: " + locationData.lights + "."
      );

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${selectedOptionsData.imageService.endpoint}`,
          {
            bookUuid: pageParams.id,
            narrativePrompt: locationlDescription.join(" "),
            options: selectedOptionsData,
          },
          { headers }
        )
        .then(response => {
          log.trace("reponse.data");
          log.trace(response.data);

          const imageUrl =
            `${process.env.REACT_APP_FIREBASE_STORAGE_URL}/` +
            response.data.filePath;

          //set form data with image generated. image is already stored, so just save the file path
          setLocationData({
            ...locationData,
            imagePrompt: response.data.imagePrompt,
            backgroundImage: response.data.filePath,
            imageUrl: imageUrl,
          });
        })
        .catch(error => {
          log.error(error);

          if (error.status == 460) {
            setAlertMessage({
              message:
                "Membership generation limits reached. Please upgrade your membership or wait for the next cycle to continue.",
              severity: "error", //success info warning error
            });
          } else {
            setAlertMessage({
              message:
                "Could not generate an image suggestion at this time. Please try again or contact support.",
              severity: "error", //success info warning error
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error generating suggestion", error);
      // Handle the error, e.g., display an error message to the user
    }
  };

  const handleOpenImageGenerationDialog = () => {
    log.trace("handleOpenImageGenerationDialog");

    // TODO how to properly handle no characters or location options
    setImageGenerationOptions({
      ...imageGenerationOptions,
      useCharacters: "hidden",
      useLocation: "hidden",
    });

    setOpenImageGenerationDialog(true);
  };

  const handleCloseImageGenerationDialog = selectedOptionsData => {
    log.trace("handleCloseImageGenerationDialog");
    log.trace(selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);

    setOpenImageGenerationDialog(false);
  };

  const handleContinueImageGeneration = async selectedOptionsData => {
    log.trace("handleContinueImageGeneration");
    log.trace(selectedOptionsData);
    setImageGenerationOptions(selectedOptionsData);
    setOpenImageGenerationDialog(false);

    suggestBackgroundImageFromDescription(selectedOptionsData);
  };

  const handleOpenImagePickerDialog = () => {
    setOpenImagePickerDialog(true);
  };

  const handleCloseImagePickerDialog = selectedOptionsData => {
    log.trace("handleCloseImagePickerDialog");
    log.trace(selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);

    setOpenImagePickerDialog(false);
  };

  const handleContinueImagePicker = async selectedOptionsData => {
    log.trace("handleContinueImagePicker");
    log.trace(selectedOptionsData);
    setImagePickerOptions(selectedOptionsData);
    setOpenImagePickerDialog(false);

    //from computer or from gallery, ...
    if (!selectedOptionsData) {
      log.trace("from computer");
      imagePickerSelectFromComputer();
    } else {
      log.trace("from gallery");
      const filePath = selectedOptionsData.filePath;
      const imagePrompt = selectedOptionsData.imagePrompt;
      const imageUrl = selectedOptionsData.imageUrl;

      //set form data with image previously generated. image is already stored, so just get the file path
      setLocationData({
        ...locationData,
        imagePrompt: imagePrompt,
        backgroundImage: filePath,
        imageUrl: imageUrl,
        newBackgroundImage: null,
      });
    }
  };

  const imagePickerSelectFromComputer = () => {
    uploadInputRef.current && uploadInputRef.current.click();
  };

  const handleOpenLocationAmbianceSetupDialog = (e, selectedOptionsData) => {
    if (selectedOptionsData) {
      setLocationAmbianceOptions(selectedOptionsData);
    }
    setOpenLocationAmbianceSetupDialog(true);
    log.trace(locationAmbianceOptions);
  };

  const handleCloseLocationAmbianceSetupDialog = selectedOptionsData => {
    // setLocationAmbianceOptions(selectedOptionsData);
    setOpenLocationAmbianceSetupDialog(false);
  };

  const handleContinueLocationAmbianceSetupDialog =
    async selectedOptionsData => {
      setLocationAmbianceOptions(selectedOptionsData);
      setOpenLocationAmbianceSetupDialog(false);
      log.trace(selectedOptionsData);
    };

  const suggestAmbianceSetupFromDetails = async () => {
    log.trace("suggestAmbianceSetupFromDetails");

    if (!locationData.backstory || locationData.backstory.length == 0) {
      setAlertMessage({
        message:
          "Please describe the location. The richer the description, the more accurate the suggestion will be.",
        severity: "error", //success info warning error
      });

      return;
    }

    setAlertMessage({});
    setLoading(true);

    try {
      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      //genai to produce suggested text
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/suggestLocationAmbiance`,
          {
            bookUuid: pageParams.id,
            location: locationData,
          },
          { headers }
        )
        .then(suggestedAmbiance => {
          log.trace("suggestedAmbiance");
          log.trace(suggestedAmbiance.data);

          setLocationAmbianceOptions(suggestedAmbiance.data);
          handleOpenLocationAmbianceSetupDialog(null, suggestedAmbiance.data);
        })
        .catch(error => {
          log.error(error);

          if (error.status == 460) {
            setAlertMessage({
              message:
                "Membership generation limits reached. Please upgrade your membership or wait for the next cycle to continue.",
              severity: "error", //success info warning error
            });
          } else {
            setAlertMessage({
              message:
                "Could not generate a suggestion at this time. Please try again or contact support.",
              severity: "error", //success info warning error
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error generating suggestion", error);
      // Handle the error, e.g., display an error message to the user
    }
  };

  const uploadInputRef = useRef(null);

  return (
    <Box component="section" sx={{}}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <ImageGenerationDialog
            title="Image Generation Options"
            message="Select the options for generating the page background image."
            open={openImageGenerationDialog}
            onClose={handleCloseImageGenerationDialog}
            onContinue={handleContinueImageGeneration}
            optionsData={imageGenerationOptions}
          />

          <ImagePicker
            title="Image Selector"
            message="Select an image from your gallery or from your computer."
            open={openImagePickerDialog}
            onClose={handleCloseImagePickerDialog}
            onContinue={handleContinueImagePicker}
            optionsData={imagePickerOptions}
          />

          <LocationAmbianceSetupDialog
            title="Ambiance Options"
            message="Setup the ambiance for this location."
            open={openLocationAmbianceSetupDialog}
            onClose={handleCloseLocationAmbianceSetupDialog}
            onContinue={handleContinueLocationAmbianceSetupDialog}
            optionsData={locationAmbianceOptions}
          />

          <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="location-details-content"
                id="location-details-header"
              >
                Location Details
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                  <TextField
                    id="name"
                    name="name"
                    required
                    autoFocus
                    value={locationData.name}
                    onChange={handleInputChange}
                    label="Name"
                    variant="outlined"
                  />
                  <TextField
                    id="backstory"
                    name="backstory"
                    required
                    multiline
                    value={locationData.backstory}
                    onChange={handleInputChange}
                    label="Backstory"
                    variant="outlined"
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="location-details-content"
                id="location-details-header"
              >
                Expanded Details
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2} direction="column" sx={{ p: 2 }}>
                  <TextField
                    id="sights"
                    name="sights"
                    value={locationData.sights}
                    onChange={handleInputChange}
                    label="Sights"
                    variant="outlined"
                  />
                  <TextField
                    id="weather"
                    name="weather"
                    value={locationData.weather}
                    onChange={handleInputChange}
                    label="Weather Elements"
                    variant="outlined"
                  />
                  <TextField
                    id="sounds"
                    name="sounds"
                    value={locationData.sounds}
                    onChange={handleInputChange}
                    label="Sounds & Noise"
                    variant="outlined"
                  />
                  <TextField
                    id="smells"
                    name="smells"
                    value={locationData.smells}
                    onChange={handleInputChange}
                    label="Smells"
                    variant="outlined"
                  />
                  <TextField
                    id="lights"
                    name="lights"
                    value={locationData.lights}
                    onChange={handleInputChange}
                    label="Light & Time of day"
                    variant="outlined"
                  />
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ p: 2, justifyContent: "center" }}
                  >
                    <Button
                      variant="text"
                      onClick={handleOpenImageGenerationDialog}
                      startIcon={<AddPhotoAlternateIcon />}
                    >
                      Suggest Location Image
                    </Button>
                    <Button
                      variant="text"
                      onClick={suggestAmbianceSetupFromDetails}
                      startIcon={<AutoFixHighIcon />}
                    >
                      Suggest Ambiance Setup
                    </Button>
                    <Button
                      variant="text"
                      onClick={handleOpenLocationAmbianceSetupDialog}
                      startIcon={<AirIcon />}
                      sx={{ ml: "auto" }}
                    >
                      Ambiance Setup
                    </Button>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="images-content"
                id="images-header"
              >
                Media
              </AccordionSummary>
              <AccordionDetails>
                <Box component="section" sx={{ p: 2 }}>
                  <Button
                    onClick={handleOpenImagePickerDialog}
                    variant="contained"
                  >
                    Select Background Image
                  </Button>

                  {locationData.backgroundImage && (
                    <Box sx={{ pt: 2, pb: 2 }}>
                      <Avatar
                        alt={locationData.backgroundImage}
                        src={locationData.imageUrl}
                        variant="square"
                        sx={{ width: "100%", height: "30%" }}
                        onLoad={handlePageBackgroundImageLoaded}
                      />
                      <Typography
                        variant="body2"
                        id="backgroundImage"
                        name="backgroundImage"
                        sx={{ textAlign: "right" }}
                      >
                        {locationData.backgroundImage}
                      </Typography>
                    </Box>
                  )}
                  <input
                    id="newBackgroundImage"
                    name="newBackgroundImage"
                    ref={uploadInputRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>

            <Stack spacing={2} direction="row" sx={{ p: 2 }}>
              <Button variant="contained" type="submit" color="primary">
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Location;
