import {
  Box,
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

/**
    https://dictionaryapi.dev/


 */

const DictionaryDefinition = ({ language, word, partOfSpeech }) => {
  const [wordDefinitions, setWordDefinitions] = useState([]);
  const [error, setError] = useState(false);

  const dictionaryCache = {};
  const getDictionaryDefinition = async () => {
    log.trace("DictionaryDefinition language " + language);
    log.trace("DictionaryDefinition word " + word);
    setError(false);

    try {
      if (!word || word.length == 0 || !language || language.length == 0) {
        return;
      }

      if (dictionaryCache[word]) {
        return dictionaryCache[word];
      }

      axios
        .get(`${process.env.REACT_APP_DICTIONARY_API_URL}/${language}/${word}`)
        .then(response => {
          let filteredData = response.data;
          if (partOfSpeech && partOfSpeech.length > 0) {
            filteredData = response.data.map(definition => ({
              ...definition,
              meanings: definition.meanings.filter(meaning =>
                partOfSpeech.includes(meaning.partOfSpeech)
              ),
            }));
          }

          setWordDefinitions(filteredData);
          dictionaryCache[word] = filteredData;
          log.trace(wordDefinitions);
        })
        .catch(error => {
          log.trace(error);
          setError(true);
          setWordDefinitions([]);
        });
    } catch (error) {
      log.trace(error);
      setError(true);
      setWordDefinitions([]);
    }
  };

  useEffect(() => {
    log.trace("DictionaryDefinition useEffect");

    getDictionaryDefinition();
  }, [language, word]);

  return (
    <Box>
      {!word ? (
        <></>
      ) : (
        <Box>
          <Typography variant="h5">{word}</Typography>

          {error && (
            <Stack direction="row" spacing={1}>
              <Typography variant="body1" sx={{ p: 2 }}>
                Couldn&apos;t fetch word definition. Please try an external
                tool.
              </Typography>
            </Stack>
          )}

          {wordDefinitions.slice(0, 1).map(definition => (
            <Typography key={definition.phonetic} variant="h5" component="div">
              {definition.phonetic}
            </Typography>
          ))}

          <Box
            style={{
              maxHeight: "390px",
              overflowX: "auto",
            }}
          >
            <Stack direction="row" spacing={2}>
              {wordDefinitions.map(definition => (
                <>
                  {definition.meanings.map((meaning, i) => (
                    <Card variant="outlined" key={i}>
                      <CardContent>
                        <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                          {meaning.partOfSpeech}
                        </Typography>
                        <List>
                          {meaning.definitions.map((def, j) => (
                            <ListItem key={j}>
                              <ListItemText
                                primary={def.definition ?? null}
                                secondary={def.example ?? null}
                              />
                            </ListItem>
                          ))}
                        </List>
                        {meaning.synonyms.length > 0 && (
                          <>
                            <Typography
                              sx={{ color: "text.secondary", mb: 1.5 }}
                            >
                              Synonyms
                            </Typography>
                            <List>
                              {meaning.synonyms.map((syn, l) => (
                                <ListItem key={l}>
                                  <ListItemText primary={syn ?? null} />
                                </ListItem>
                              ))}
                            </List>
                          </>
                        )}
                        {meaning.antonyms.length > 0 && (
                          <>
                            <Typography
                              sx={{ color: "text.secondary", mb: 1.5 }}
                            >
                              Antonyms
                            </Typography>
                            <List>
                              {meaning.antonyms.map((ant, k) => (
                                <ListItem key={k}>
                                  <ListItemText primary={ant} />
                                </ListItem>
                              ))}
                            </List>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  ))}
                </>
              ))}
            </Stack>
            <Stack direction="row" spacing={1}>
              <Link
                href={
                  "https://www.merriam-webster.com/dictionary/" +
                  encodeURIComponent(word)
                }
                target="_blank"
                rel="noreferrer"
                underline="hover"
              >
                Merriam-Webster
              </Link>
              <Typography variant="body1">,</Typography>
              <Link
                href={
                  "https://translate.google.com/?sl=en&op=translate&text=" +
                  encodeURIComponent(word)
                }
                target="_blank"
                rel="noreferrer"
                underline="hover"
              >
                Google Translate
              </Link>
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DictionaryDefinition;
