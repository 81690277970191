import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadgeIcon from "@mui/icons-material/Badge";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ForumIcon from "@mui/icons-material/Forum";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { PageContainer } from "@toolpad/core/PageContainer";
import React, { useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

const NAVIGATION = [
  {
    kind: "header",
    title: "Home",
  },
  {
    segment: "console",
    title: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    segment: "console/books",
    title: "Books",
    icon: <MenuBookIcon />,
  },
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Analytics",
  },
  {
    segment: "console/reports/feedback",
    title: "User Feedback",
    icon: <ForumIcon />,
  },
  {
    segment: "console/reports/traffic",
    title: "Traffic",
    icon: <SsidChartIcon />,
  },
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Account",
  },
  {
    segment: "console/membership",
    title: "Membership",
    icon: <BadgeIcon />,
  },
  {
    segment: "console/profile",
    title: "Profile",
    icon: <AccountCircleIcon />,
  },
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Help",
  },
  {
    segment: "console/learningcenter",
    title: "Learning Center",
    icon: <SchoolIcon />,
  },
];

function ConsoleLayout() {
  const location = useLocation();
  const navigate = useNavigate();

  const [pathname, setPathname] = useState(location.pathname);

  const router = useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: path => {
        setPathname(String(path));
        navigate(path);
      },
    };
  }, [navigate, pathname]);

  const BRANDING = { logo: "", title: "LIBELL.US" };

  return (
    // preview-start
    <AppProvider navigation={NAVIGATION} router={router} branding={BRANDING}>
      <DashboardLayout>
        <PageContainer>
          <Outlet />
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
    // preview-end
  );
}

export default ConsoleLayout;
