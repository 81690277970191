import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

const ImageGenerationDialog = ({
  title,
  message,
  open,
  onClose,
  onContinue,
  optionsData,
}) => {
  const [options, setOptions] = useState({
    useLocation: false,
    useCharacters: false,
    useImageDescriptorService: false,
  });

  useEffect(() => {
    setOptions(optionsData);
  }, [open, optionsData]);

  const handleConfirmation = e => {
    onContinue(options);
  };

  const handleDialogClose = e => {
    onClose(options);
  };

  const handleSwitchInputChange = e => {
    const { name, checked } = e.target;
    setOptions({ ...options, [name]: checked });
  };

  const handleImageServiceInputChange = (event, value) => {
    setOptions({ ...options, imageService: value });
  };

  const imageGeneratorServicesList = {
    options: [
      { name: "Amazon AWS (Titan)", endpoint: "imageIdeationBasedOnPromptAWS" },
      {
        name: "Google (AIPlatform Imagen)",
        endpoint: "imageIdeationBasedOnPromptGAI",
      },
      // {
      //   name: "Google (VertexAI Imagen)",
      //   endpoint: "imageIdeationBasedOnPromptVAI",
      // },
      {
        name: "Microsoft (Azure OpenAI)",
        endpoint: "imageIdeationBasedOnPromptAzure",
      },
      { name: "OpenAI (DALL·E)", endpoint: "imageIdeationBasedOnPromptDalle" },
    ],
    getOptionLabel: option => option.name,
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <FormGroup>
          <Autocomplete
            {...imageGeneratorServicesList}
            id="image-services-auto-complete"
            name="image-services-auto-complete"
            fullWidth
            autoComplete
            filterSelectedOptions
            onChange={handleImageServiceInputChange}
            value={options.imageService}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    variant="filled"
                    label={option.label}
                    key={key}
                    {...tagProps}
                  />
                );
              })
            }
            renderInput={service => (
              <TextField
                {...service}
                key={service.label}
                variant="standard"
                label="Image Service"
              />
            )}
          />
          {options.useLocation !== "hidden" && (
            <Tooltip
              arrow
              title="If no location is specified, a random one is selected."
            >
              <FormControlLabel
                control={
                  <Switch
                    name="useLocation"
                    id="useLocation"
                    checked={options.useLocation}
                    onChange={handleSwitchInputChange}
                  />
                }
                label="Use location"
              />
            </Tooltip>
          )}
          {options.useCharacters !== "hidden" && (
            <Tooltip
              arrow
              title="If no characters are specified, a random one is selected."
            >
              <FormControlLabel
                control={
                  <Switch
                    name="useCharacters"
                    id="useCharacters"
                    checked={options.useCharacters}
                    onChange={handleSwitchInputChange}
                  />
                }
                label="Use characters"
              />
            </Tooltip>
          )}
          <Tooltip
            arrow
            title="In some cases this improves the generation accuracy. An AI assistant will read all your page information and make its best attempt at describing the important elements of an image representing these events."
          >
            <FormControlLabel
              control={
                <Switch
                  name="useImageDescriptorService"
                  id="useImageDescriptorService"
                  checked={options.useImageDescriptorService}
                  onChange={handleSwitchInputChange}
                />
              }
              label="2-steps generator: use image descriptor service"
            />
          </Tooltip>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmation} color="primary">
          Continue
        </Button>
        <Button onClick={handleDialogClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageGenerationDialog;
