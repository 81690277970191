import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

function LearningPhysicalDetails() {
  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Typography variant="h6">Personality</Typography>
      <Divider sx={{ p: 1 }} />
      <Typography variant="subtitle1" sx={{ fontStyle: "italic", p: 2 }}>
        &quot;The way that someone or something looks.&quot;
      </Typography>
      <Typography variant="body1"></Typography>
      Visual appearance is a general concept that includes also various other
      visual phenomena, such as color, visual texture, visual perception of
      shape, size, etc. This is optional and can be used to describe the
      character&apos;s physical appearance. You can describe an overview or dive
      into specifics as needed.
    </Box>
  );
}

export default LearningPhysicalDetails;
