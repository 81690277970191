import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

/**
    https://www.sciencedirect.com/topics/social-sciences/personality-trait#:~:text=Briefly%2C%20a%20personality%20trait%20refers,fairly%20long%20period%20of%20time 

    A personality trait refers to differences among individuals in a typical tendency to behave, think, or feel in 
    some conceptually related ways, across a variety of relevant situations and across some fairly long period of 
    time. But this definition has several parts, some of which could be stated more precisely. Let us discuss this 
    definition a bit further.

    First, the idea of “differences among individuals” is important, because the description of an individual's 
    personality is meaningful only to the extent that it gives us, directly or indirectly, a comparison with others. 

    Next, “in a tendency to behave, think, or feel” refers to a likelihood of showing some behaviors or of having 
    some thoughts or feelings. 
 */

function LearningHierarchyOfNeeds() {
  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Typography variant="h6">Hierarchy of needs</Typography>
      <Divider sx={{ p: 1 }} />
      <Typography variant="subtitle1" sx={{ fontStyle: "italic", p: 2 }}>
        Maslow&quot;s hierarchy of needs is an idea in psychology proposed by
        American psychologist Abraham Maslow in his 1943 paper &quot;A Theory of
        Human Motivation&quot; in the journal Psychological Review. The
        hierarchy of needs is a psychological idea and an assessment tool,
        particularly in education, healthcare and social work.
      </Typography>
      <Typography variant="body1">
        Events in a character life will impact their hierarchy of needs,
        hindering or catalizing personality and traits behaviors.
      </Typography>
      <Typography variant="body1">
        Choose the starting point for the character in the story.
      </Typography>
    </Box>
  );
}

export default LearningHierarchyOfNeeds;
