import {
  Alert,
  Avatar,
  Box,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "./Services/auth";
import { logEvent } from "./Services/firebase";
import log from "./Tools/Log";

function PublicProfile() {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
  };
  const navigate = useNavigate();
  const pageParams = useParams();

  const [userProfileData, setUserProfileData] = useState({
    displayName: "",
    email: "",
    emailVerified: "",
    penName: "",
    membership: {},
  });
  const [alertMessage, setAlertMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getUserProfile`,
          {},
          { headers }
        )
        .then(response => {
          log.trace("response.data", response.data);
          log.trace(response.data);

          setUserProfileData(response.data);
        })
        .catch(error => {
          log.error(error);

          // TODO auto create profile?
          // if new member without profile, redirect
          if (error.status == 310) {
            // 310 - not a member
            navigate("/newuserprofile");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 130,
        height: 130,
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const handleSignOut = async () => {
    navigate("/logout");
  };

  return (
    <Box component="section" sx={{}}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}
      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section">
          <Stack direction="row" spacing={2}>
            <Stack direction="column">
              {firebaseAuth.currentUser.photoURL ? (
                <Avatar
                  sx={{ width: 130, height: 130 }}
                  alt={firebaseAuth.currentUser.displayName}
                  src={firebaseAuth.currentUser.photoURL}
                />
              ) : (
                <Avatar
                  {...stringAvatar(firebaseAuth.currentUser.displayName)}
                />
              )}
              <Button
                key="Logout"
                variant="outlined"
                sx={{ my: 2 }}
                onClick={handleSignOut}
              >
                Logout
              </Button>
            </Stack>

            <Box>
              <Stack direction="column">
                <Typography variant="h5">
                  {userProfileData.displayName}
                </Typography>
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ display: "block" }}
                >
                  display name
                </Typography>
              </Stack>
              <Stack direction="column">
                <Typography variant="h5">{userProfileData.email}</Typography>
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ display: "block" }}
                >
                  email
                </Typography>
              </Stack>
              {userProfileData.penName && (
                <Stack direction="column">
                  <Typography variant="h6">
                    {userProfileData.penName}
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    pen name
                  </Typography>
                </Stack>
              )}
            </Box>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default PublicProfile;
