import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import axios from "axios";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import firebaseAuth from "./Services/auth";
import { logEvent } from "./Services/firebase";
import log from "./Tools/Log";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    log.trace(firebaseAuth.currentUser);

    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebaseAuth);

    if (!firebaseAuth.currentUser) {
      ui.start("#firebaseui-auth-container", firebaseUIConfig);
    }
  }, []);

  // Configure FirebaseUI.
  const firebaseUIConfig = {
    signInFlow: "popup",
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        // Action if the user is authenticated successfully
        log.trace("signInSuccessWithAuthResult");

        log.trace("authResult");
        log.trace(authResult);
        log.trace("redirectUrl");
        log.trace(redirectUrl);

        if (authResult.user) {
          handleSignedInUser(authResult.user, authResult.additionalUserInfo);
        }

        // Do not redirect.
        return false;
      },
      uiShown: function () {
        // This is what should happen when the form is full loaded. In this example, I hide the loader element.
        //document.getElementById('loader').style.display = 'none';
      },
    },
    signInSuccessUrl: "https://libell.us",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      {
        prompt: "consent",
        provider: "microsoft.com",
        loginHintKey: "login_hint",
      },
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    tosUrl: "https://libell.us/tos", // URL to you terms and conditions.
    privacyPolicyUrl: function () {
      // URL to your privacy policy
      window.location.assign("https://libell.us/privacy");
    },
  };

  /**
   * Displays the UI for a signed in user.
   * @param {!firebase.User} user
   */
  var handleSignedInUser = function (user, additionalUserInfo) {
    log.trace("handleSignedInUser");
    log.trace(user);

    checkUserAuthorization(user, additionalUserInfo);
  };

  // TODO this is a DEV only method; check what's best for prod later
  var checkUserAuthorization = async function (user, additionalUserInfo) {
    log.trace("checkUserAuthorization");
    setLoading(true);

    const headers = {
      "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
      Authorization: `Bearer ${await firebaseAuth.currentUser.getIdToken()}`,
      "Content-Type": "application/json",
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/checkAuth`, {}, { headers })
      .then(() => {
        navigate("/checkAccountStateInterstitial");
      })
      .catch(error => {
        log.trace(error);

        if (error.status == 310) {
          // 310 - not a member
          navigate("/newuserprofile");
        } else if (error.status == 311) {
          // 311 - no membership
          navigate("/newusermembership");
        } else if (error.status == 312) {
          // 312 - not allow-listed
          navigate("/betainvite");
        } else if (error.status == 313) {
          // 313 - already subscribed
          navigate("/console"); // TODO check loop cycles
        } else if (error.status == 314) {
          // 314 - session paid; no membership created yet
          navigate("/subscribesuccess");
        } else {
          // server error
          log.error(error);
          firebaseAuth.signOut().then(() => {
            navigate("/error");
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  var handleSignOut = function () {
    log.trace("handleSignOut");
    firebaseAuth.signOut().then(() => {
      navigate("/");
    });
  };

  /**
   * Displays the UI for a signed out user.
   */
  var handleSignedOutUser = function () {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebaseAuth);

    log.trace("handleSignedOutUser");
    ui.start("#firebaseui-auth-container", firebaseUIConfig);
  };

  // Listen to change in auth state so it displays the correct UI for when
  // the user is signed in or not.
  firebaseAuth.onAuthStateChanged(user => {
    log.trace("onAuthStateChanged");
    log.trace(user);

    user ? handleSignedInUser(user) : handleSignedOutUser();
  });

  return (
    <Box component="section" sx={{}}>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {firebaseAuth.currentUser ? (
            <Box component="section" sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Typography component="body1">Hello</Typography>
                <Typography component="body1">
                  {firebaseAuth.currentUser.displayName}
                </Typography>
                <Typography component="body1">
                  {firebaseAuth.currentUser.email}
                </Typography>
                <Button variant="outlined" onClick={handleSignOut}>
                  Sign Out
                </Button>
              </Stack>
            </Box>
          ) : (
            <Box component="section" sx={{ p: 2 }}>
              <Typography component="h6" sx={{ textAlign: "center" }}>
                Login with your preferred account
              </Typography>
              <Box id="firebaseui-auth-container"></Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default Login;
