import {
  Alert,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebaseAuth from "../../Services/auth";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

function BookOverview() {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
  };
  const navigate = useNavigate();
  const pageParams = useParams();

  const [bookData, setBookData] = useState({
    numberOfPages: 0,
    numberOfCharacters: 0,
    numberOfLocations: 0,
    numberOfTimelines: 0,
  });
  const [alertMessage, setAlertMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!pageParams.id) {
      navigate("/console/books");
    }

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;
      headers["Content-Type"] = "application/json";

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getBookOverview`,
          { bookUuid: pageParams.id },
          { headers }
        )
        .then(response => {
          log.trace("response.data", response.data);
          log.trace(response.data);

          setBookData(response.data);
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  return (
    <Box component="section" sx={{}}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}
      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section">
          <Stack direction="row" spacing={2}>
            <Card
              variant="outlined"
              sx={{ textAlign: "center", width: "150px" }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  PAGES
                </Typography>
                <Typography variant="h5" component="div">
                  {bookData.numberOfPages}
                </Typography>
              </CardContent>
            </Card>

            <Card
              variant="outlined"
              sx={{ textAlign: "center", width: "150px" }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  CHARACTERS
                </Typography>
                <Typography variant="h5" component="div">
                  {bookData.numberOfCharacters}
                </Typography>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{ textAlign: "center", width: "150px" }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  LOCATIONS
                </Typography>
                <Typography variant="h5" component="div">
                  {bookData.numberOfLocations}
                </Typography>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{ textAlign: "center", width: "150px" }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  TIMELINES
                </Typography>
                <Typography variant="h5" component="div">
                  {bookData.numberOfTimelines}
                </Typography>
              </CardContent>
            </Card>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default BookOverview;
