import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { logEvent } from "../../Services/firebase";
import log from "../../Tools/Log";

function LearningAudioDetails() {
  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Typography variant="h6">Audio Details</Typography>
      <Divider sx={{ p: 1 }} />
      <Typography variant="body1">Learning</Typography>
    </Box>
  );
}

export default LearningAudioDetails;
