import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import firebaseAuth from "../Services/auth";
import { logEvent } from "../Services/firebase";
import log from "../Tools/Log";

function NewUserMembership() {
  const headers = {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS}`,
    "Content-Type": "application/json",
  };
  const navigate = useNavigate();

  const [userProfileData, setUserProfileData] = useState({
    displayName: "",
    email: "",
    emailVerified: "",
    penName: "",
    customerId: "",
    membership: {},
  });
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [membershipPackages, setMembershipPackages] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setAlertMessage({});

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getUserProfile`,
          {},
          { headers }
        )
        .then(response => {
          //   log.trace("response.data", response.data);
          //   log.trace(response.data);

          setUserProfileData(response.data);

          // page not intended for current active members
          if (
            response.data.membership &&
            response.data.membership.status === "active"
          ) {
            navigate("/console");
          }
        })
        .catch(error => {
          log.error(error);
        });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getProductOfferings`,
          {},
          { headers }
        )
        .then(response => {
          log.trace("response.data", response.data);
          log.trace(response.data);

          setMembershipPackages(response.data);
        })
        .catch(error => {
          log.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleFreeMembership = async (e, pkg) => {
    log.trace("handleFreeMembership");
    log.trace(pkg);

    // TODO; previous memberships should have a history; never overwritten
    try {
      setLoading(true);
      setAlertMessage({});

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/freeSubscription`,
          { subscriptionId: pkg.uuid },
          { headers }
        )
        .then(response => {
          //   log.trace("response.data", response.data);
          //   log.trace(response.data);

          navigate("/getapp");
        })
        .catch(error => {
          log.error(error);
          setAlertMessage({
            message:
              "Our apologies! Could not create the membership information. Please try again.",
            severity: "error", //success info warning error
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      log.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleSubscribe = async (e, pkg) => {
    log.trace("handleSubscribe");
    log.trace(pkg);

    try {
      e.preventDefault();

      setLoading(true);
      setAlertMessage({});

      headers["Authorization"] =
        `Bearer ${await firebaseAuth.currentUser.getIdToken()}`;

      const payload = {
        subscriptionId: pkg.uuid,
        subscriptionPrice: pkg.price,
      };

      // if returning customer, pass in data so we can link the new subscription to the existing customer
      // when not passing in data, a new customer will be created during checkout
      if (userProfileData.customerId) {
        payload.userCustomerId = userProfileData.customerId;
      }
      // pass in metadata to make it easy to connect stripe and libell.us customers
      payload.userDisplayName = userProfileData.displayName;
      payload.userEmail = userProfileData.email;

      axios
        .post(`${process.env.REACT_APP_API_URL}/commerceCheckout`, payload, {
          headers,
        })
        .then(async response => {
          log.trace("Commerce flow initiated successfully.");
          log.trace("response.data", response.data);
          log.trace(response.data);

          const stripe = await loadStripe("pk_test_TQIt1ho9OTmhyM1TzN8PMTOc");
          stripe.redirectToCheckout(response.data);
        })
        .catch(error => {
          log.error(error);
          setAlertMessage({
            message:
              "Our apologies! This subscription request could not be completed at this time. Please try again.",
            severity: "error", //success info warning error
          });
          setLoading(false);
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (error) {
      console.error("Error initiating commerce flow:", error);
      setLoading(false);
      setAlertMessage({
        message:
          "Our apologies! This subscription request could not be completed at this time. Please try again.",
        severity: "error", //success info warning error
      });
    }
  };

  return (
    <Box component="section" sx={{ p: 2 }}>
      {alertMessage.message && (
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <Box component="section" sx={{ p: 2 }}>
          <Typography variant="h5" sx={{ pb: 2 }}>
            Hello {userProfileData.displayName}! Select a plan that better fits
            your creativity cravings.
          </Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            You can cancel or resubscribe anytime with few clicks. No payment
            information is ever stored. Your data is protected by Stripe.
          </Typography>
          <Stack spacing={2} direction="row">
            {membershipPackages.map(pkg => (
              <Card
                key={pkg.uuid}
                sx={{
                  width: 345,
                  height: 550,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  sx={{ minHeight: 150, height: 150 }}
                  image={pkg.imageUrl}
                  title={pkg.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {pkg.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      whiteSpace: "pre-line",
                      color: "text.secondary",
                    }}
                  >
                    {pkg.description}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      color: "text.secondary",
                    }}
                  >
                    What you get:
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText>
                        {pkg.aiTextGenPerCycle} AI text generations per cycle
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        {pkg.aiImageGenPerCycle} AI image generations per cycle
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        {pkg.aiAudioGenPerCycle} AI audio generations per cycle
                      </ListItemText>
                    </ListItem>
                  </List>

                  {pkg.price ? (
                    <Typography
                      multiline
                      variant="h6"
                      sx={{ color: "text.secondary" }}
                    >
                      ${pkg.price}/{pkg.period}
                    </Typography>
                  ) : (
                    <Typography
                      multiline
                      variant="h6"
                      sx={{ fontStyle: "italic", color: "text.secondary" }}
                    >
                      free
                    </Typography>
                  )}
                </CardContent>
                <CardActions sx={{ mt: "auto" }}>
                  {pkg.price ? (
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      onClick={e => handleSubscribe(e, pkg)}
                    >
                      Subscribe
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={e => handleFreeMembership(e, pkg)}
                    >
                      Get Started
                    </Button>
                  )}
                </CardActions>
              </Card>
            ))}
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default NewUserMembership;
